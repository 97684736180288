.otp {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  background-image: var(--primary-bg-color);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;

  width: 40%;
  overflow: hidden;
}

.otp-verification-wrap-container {
  min-height: 85vh;
}

.otp .title {
  font-family: var(--secondary-font);
  font-weight: 900;
  font-size: var(--size-4);
  color: var(--primary-bg-color)
}

.otp-body .p-1 {
  color: #666666;
  font-family: var(--secondary-bg-color);
}

.otp-inputs {
  margin-top: var(--size-5);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.otp-inputs input {
  border: 1px solid var(--secondary-bg-color);
  width: 50px;
  max-width: 50px;
  height: 50px;
  max-height: 50px;
  border-radius: 10px;
  text-align: center;
  font-family: "Poppins";
}

.resend-confirmation p {
  font-family: var(--primary-font);
}

.btn-1 {
  border: 1px solid var(--secondary-bg-color);
  color: var(--secondary-bg-color);
  border-radius: 0.6rem;

  font-weight: var(--primary-font-weight);

  height: var(--size-6);
}



.btn-2 {
  background: var(--primary-bg-color);
  color: #fff;
  border-radius: 0.6rem;

  height: var(--size-6);
  font-weight: var(--primary-font-weight);

}

.otp-buttons {
  position: absolute;
  bottom: 1.5rem;
  width: 85%;
}

.svg-wrapper-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.opt-screen {
  height: 85vh;
}

.sign-up-otp-btn {
  position: absolute;
  bottom: 2rem;
}

.otp-bottom {
  position: absolute;
  bottom: 1rem;
}

@media (max-width: 1000px) {
  .otp {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
  }

  /* .otp svg {
    transform: scale(1.5);
  }

  .otp-body p-1 {
    font-size: 1.2rem;
  }


  .otp-btns button {
    font-size: 1.2rem;
  }

  .otp-inputs input,
  .resend-confirmation p {
    font-size: 1rem;
  } */
}