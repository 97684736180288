.show-business-side {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 20;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.bio {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--primary-font-size) !important;
}

.bio .title {
  font-size: var(--size-2);
  font-weight: var(--primary-font-weight);
}

.add-business-info .title {
  font-family: var(--secondary-font);
  font-weight: var(--bolder-font);
  font-size: var(--size-2-5);
}

.upload-camera {
  border: 1px dotted #b695f8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.inner-upload {
  background-color: #efecf4;
  border-radius: 50%;
  height: 100%;
}

.upload-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 9px;
}

.business-logo {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size) !important;
}

.business-form label {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size) !important;
}

.first-input {
  width: 100%;
  border: 1px solid #979797;
  min-height: 6vh;
}

.business-form input {
  width: 100%;
  border: 1px solid #979797;
  min-height: 6vh;
  font-size: 12px;
}

.add-btn {
  background: var(--primary-bg-color);
  font-family: var(--secondary-font);
  min-height: var(--size-6);
  font-size: var(--primary-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 770px) {
  .inner-right {
    grid-column-start: 1;
    grid-column-end: 7;
  }
}

@media (max-width: 1000px) {
  /* .add-btn {
    font-size: 1rem;
  } */

  .add-business-info .title {
    font-size: 1.2rem;
  }
}

.update-business-btn {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  font-family: "Fira Sans";
  font-weight: 400;
  font-size: 0.7rem;
}


.bio-length {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--primary-font-size);
}

.user-icon-dummy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.user-icon-default {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}