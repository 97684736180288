.email-success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.email-success-outer {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;

  overflow: auto;
  width: 40%;

  font-family: "Poppins";
  font-family: "Poppins";
}

.envelope-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
}

.congrats h1 {
  font-family: "Fira Sans";
  font-weight: 600;
  font-size: 38px;
}

.congrats p {
  font-family: "Poppins";
  font-weight: 500;
}

.proceed-btn button {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
}

@media (max-width: 1000px) {
  .email-success-outer {
    width: 100%;
    margin: 1rem;
  }
}