.invite-people-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  border-radius: 8px;
  font-size: "fira sans";
  font-weight: 500;
  font-size: clamp(70%, 80%, 100%);
  height: 5vh;
  padding: 0 30px;
}
.people-image {
  border-radius: 50%;
  height: 6vh;
  width: 100%;
}
.people-table-body {
  height: 6vh;
  padding: 5px 0;
}

.invite-employee-employe {
  border: 1px solid #c4c2c2c2;
  border-radius: 0.75rem;
  width: 15%;
  min-height: 6vh;
  overflow: hidden;
}
.invite-employee-dates-label {
  font-family: poppins;
  font-weight: 500;
}
.invite-employee-employe input {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "poppins";
  font-weight: 500;
  font-size: 75%;
  text-align: center;
}
.invite-employee-email {
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.75rem;
  border: 1px solid #979797;
  border-radius: 10px;
  width: 100%;
  padding: 0 10px;
  min-height: 6vh;
}
.invite-employee-text {
  background-image: linear-gradient(#101828, #0a1e46);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "poppins";
  font-weight: 500;
}
.invite-emp-check-text {
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.75rem;
}
.ad-manualy-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  font-family: "fira sans";
  font-weight: 500;
  font-size: 1vw;
  border-radius: 0.5rem;
  min-height: 6vh;
  padding: 0 0.9rem;
}
.invite-emp-button {
  border: 1px solid #979797;
  color: #979797;
  font-family: "fira sans";
  font-weight: 500;
  font-size: 1vw;
  border-radius: 0.5rem;
  min-height: 6vh;
  padding: 0 2rem;
}

.emp-details-toggler {
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.75rem;
  color: #979797;
  min-height: 6vh;
  padding: 0 0.5rem;
  border-radius: 0.7rem;
}
.active-emp-detail {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.75rem;
  min-height: 6vh;
  padding: 0 0.5rem;
  border-radius: 0.7rem;
}
.emp-details-togglers {
  border-bottom: 1px solid #979797;
  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

@media (max-width: 1000px) {
  .invite-people-btn {
    font-size: 100%;
    height: 5vh;
  }
  .people-table-body {
    font-size: 100%;
  }
  .people-table-body svg {
    transform: scale(1.5);
  }
  .people-table-body input {
    transform: scale(1.5);
  }
  .people-table-heading input {
    transform: scale(1.5);
  }
  .people-table-heading label {
    font-size: 100%;
  }
}
