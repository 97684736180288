.ci-top-inputs {
  font-family: "Poppins";
}
.ci-top-inputs label {
  font-size: 12px;
  font-weight: 500;
}
.ci-top-inputs input {
  width: 100%;
  height: 6vh;

  font-size: 10px;
  border: 1px solid #979797;
}

.ci-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #979797;
}

.ci-middle-inputs {
  font-family: "Poppins";
}
.ci-middle-inputs label {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
}

.ci-middle-inputs input {
  width: 100%;
  height: 6vh;

  font-size: 10px;
  border: 1px solid #979797;
}

.ci-address {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 17px;
}
.ci-lower {
  font-family: "Fira Sans";
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 1000px) {
  .ci-text {
    font-size: 1rem;
  }
}
