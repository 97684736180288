body {
  background: #f5f5f5;
  color: #000;
}

body input:focus {
  outline: none;
}

:root {
  /* --body-color: "#f5f5f5"; */
  --body-color: "#FAF9F6";
  --primary-font: "poppins";
  --secondary-font: 'fira sans';
  --primary-font-weight: 500;
  --secondary-font-weight: 400;
  --third-font-weight: 300;
  --bolder-font: 600;
  --bold-font: 700;
  --primary-font-size:      clamp(14px, 16px, 18px);  
  --secondary-font-size:  clamp(14px, 16px, 18px);
  
  /* --secondary-font-size:   clamp(0.6875rem, 0.5652rem + 0.3vw, 1.25rem);
   */
  /* --primary-font-size: 18px;
  --secondary-font-size: 14px; */
  --maxmd-font-size: 0.82rem;
  --bigger-font-size: 0.87rem;
  --big-font-size: 1.7rem;
  --lg-font-size: 2rem;
  --xl-font-size: 2.7rem;
  --sm-font: 0.6rem;
  --md-font: 0.65rem;
  --max-md-font: 0.7rem;
  --lg-font: 0.9rem;
  --xl-font: 1rem;
  --2xl-font: 1.1rem;
  --3xl-font: 1.2rem;
  --third-font-size: 0.65rem;
  --fourth-font-size: 0.6rem;
}

:root {
  --primary-bg-color: linear-gradient(180.04deg, #101828 1.28%, #0D1B37 99.81%, #0A1E46 99.96%);
  --linear-color: linear-gradient(90deg, #101828 1.49%, #0A1E46 95.79%);
  --secondary-bg-color: #979797;
  --third-bg-color: #b695f8;
  --fourth-bg-color: #F8F0E7;
  --fifth-bg-color: #E6F2E2;
  --sixth-bg-color: #F9E8E8;
  --seventh-bg-color: #EEF0F8;
  --color-8: #101828;
  --color-9: #666;
  --color-10: #2A2A2A;
  --color-11: #0d1b37;
  --color-12: #101828;
  --light-color: "#ffffff";
  --dark-color: "#000000";
  --positive-color: #008C51;
  --negitive-color: #F14747;
  --mid-negitive: #F08201;
  --dim-color: ##2A2A2A;
  --primary-shadow: box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
}

:root {
  --size-0-5: 0.5vmin;
  --size-1: 1vmin;
  --size-1-5: 1.5vmin;
  --size-2: 1rem;
  --size-2-5: 1.1rem;
  --size-3: 3vmin;
  --size-3-5: 1.3rem;
  --size-4: 1.5rem;
  --size-4-5: 1.7rem;
  --size-5: 2rem;
  --size-5-5: 2.1rem;
  /* --size-6: 3rem; */
  --size-6: 3rem;
  --size-6-5: 5.5vmin;
  --size-7: 7vmin;
  --size-7-5: 7.5vmin;
  --size-8: 8vmin;
  --size-8-5: 8.5vmin;
  --size-9: 9vmin;
  --size-9-5: 9.5vmin;
  --size-10: 10vmin;
  --size-10-5: 10.5vmin;
  --size-11: 11vmin;
  --size-11-5: 11.5vmin;
  --size-12: 12vmin;
  --size-12-5: 12.5vmin;
  --size-13: 5rem;
  --size-13-5: 13.5vmin;
  --size-14: 14vmin;
  --size-14-5: 14.5vmin;
  --size-15: 15vmin;
  --size-15-5: 15.5vmin;
  --size-16: 16vmin;
  --size-16-5: 16.5vmin;
  --size-17: 17vmin;
  --size-17-5: 17.5vmin;
  --size-18: 18vmin;
  --size-18-5: 18.5vmin;
  --size-19: 19vmin;
  --size-19-5: 19.5vmin;
  --size-20: 20vmin;
  --size-20-5: 20.5vmin;
  --size-21: 21vmin;
  --size-21-5: 21.5vmin;
  --size-22: 22vmin;
  --size-22-5: 22.5vmin;
  --size-23: 23vmin;
  --size-23-5: 23.5vmin;
  --size-24: 24vmin;
  --size-24-5: 24.5vmin;
  /* --size-25: 15%; */
  --size-25: 15rem;
  --size-25-5: 25.5vmin;
  --size-26: 26vmin;
  --size-26-5: 26.5vmin;
  --size-27: 27vmin;
  --size-27-5: 27.5vmin;
  --size-28: 28vmin;
  --size-28-5: 28.5vmin;
  --size-29: 29vmin;
  --size-29-5: 29.5vmin;
  --size-30: 30vmin;

}
/* setting up Fonts */
html{
  font-family: var(--primary-font),sans-serif;;
}

.primary-shadow {
  box-shadow: 0px 6px 16px -6px #00000029 !important;

}
.ant-switch-checked{
  background-color: #b695f8 !important;
}
  /*
.ant-switch-inner, .ant-switch-inner-unchecked {
  background-color: #979797 !important;
} */
/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: #999;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.mini-scrollbar::-webkit-scrollbar {
  margin: 2px;
  padding: 2px;
  width: 3px;
  height: 3px;
  border-radius: 10px;
}

/* Track */
.mini-scrollbar::-webkit-scrollbar-track {
  background: #dcdcdc;
}




.scrollbar-hide {
  /* Hide scrollbar */
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none!important; /* For Chrome, Safari, and Opera */
}

.hover-show-scrollbar:hover {
  /* Show scrollbar on hover */
  overflow-y: scroll!important;
}



input[type="search"]::-webkit-search-cancel-button{
  cursor : pointer; 
}

.primary-fonts {
  font-family: var(--primary-font);
  /* font-weight: var(--primary-font-weight); */
  /* font-size: var(--primary-font-size); */
}

.secondary-fonts {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  /* font-size: var(--secondary-font-size); */
}

.font-fira {
  font-family: var(--secondary-font);
}

.default-forms input {
  border: 1px solid #979797 ;
  font-family: var(--primary-font);
  /* padding-left: 1rem !important; */
}
.default-forms input {
  font-family: var(--primary-font);
}
.header-title {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 4.2vmin;
  margin-left: 60px;
  margin-top: 5px;
  color: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
}

.bg-leaner {
  background: linear-gradient(92.32deg, #101828 1.49%, #0A1E46 95.79%) !important;
  color: white !important;
}

.text-third {
  color: var(--third-bg-color);
}

.bg-third {
  background-color: var(--third-bg-color);
}


.primary-fonts {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
}

.secondary-fonts {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
}

.third-fonts-poppins {
  font-family: var(--primary-font);
  font-weight: var(--third-font-weight);
}
.third-fonts-fira {
  font-family: var(--secondary-font);
  font-weight: var(--third-font-weight);
}
.fira-sans {
  font-family: "Fira Sans";
  font-weight: bold;
  font-size: 30px;
  color: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
}

.primary-bg {
  background: var(--primary-bg-color);
  color: #fff;
}

.react-notification {
  top: 50% !important;
  transform: translateY(-50%);
}

.add-p-side {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
}

.default-fields-bg {
  background-color: rgba(182, 149, 248, 0.1);
}

.default-fields-bg input {
  background-color: rgba(192, 164, 250, 0);
  
}

.default-opacity-bg {
  background-color: rgba(182, 149, 248, 0.1);
}
.default-negative-color  {
    background-color: rgba(240, 130, 1, 0.1) !important; 
}
.default-positive-color {
  background-color: rgba(241, 71, 71, 0.1) !important; 
}

.default-positive-opacity {
  background-color: rgba(0, 140, 81, 0.1);
}

.green-color-opacity  {
background-color: rgba(43, 52, 103, 0.1);
}

.hover-effect-invert:hover svg {
  filter: invert(1);
}
.edit-form-inputs input {
 cursor: cursor;
}

.pointer-inputs input select {
  cursor: pointer;
}
.react-date-picker__wrapper {
  border-radius: 5px;
  border: 1px solid var(--secondary-bg-color) !important;
  height: 40px;
}

.width-40 {
  width: 33% !important;
}
.cnf-height {
  height: 471px !important;
}
.cnf-icon-st {
  min-width: 120px;
  height: 116.06px;
}
.cnf-title {
  font-family: 'Poppins';
  font-size: 26px;
}
.desc {
  font-family: 'Poppins';
  font-size: 18px;
}
.header {
  height: 10vh;
}

.confirmation-modal {
  font-family: 'poppins';
}

.ml-100 {
  margin-left: 100px;
}
.act-des {
font-size: 47px;
font-weight: 600;
font-family: 'Poppins';
}
.dropzone {
  border: 2px dashed #cccccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: 50%
}

.active {
  border-color: #2196f3;
}


.pdf-view::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

.pdf-view::-webkit-scrollbar-thumb {
  background: var(--primary-bg-color);
  border-radius: 10px;
}

.pdf-view::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.justify-right {
  justify-content: right;
}

.z-index {
  z-index: 999999;
}

@media (max-width: 769px) {
  .header h1 {
    margin-left: 1.2rem;
  }
}

@media (max-width: 1000px) {
  .tab-font {
    font-size: 1rem;
  }
}

/* Styles for screens less than 500px */
@media (max-width: 500px) {

  /* Hide the main content */
  body {
    overflow: hidden;
  }

  /* Show the modal */
  .mobile-modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0d1b37;
    background-image: url("./files/404.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;

    z-index: 9999;
    font-size: 24px;
    font-family: "fira sans";
    font-weight: 500;
  }
}

.social-btn {
  width: 200px;
  height: 60px;
  border-radius: 0.5rem;
}

/* Styles for screens 500px and larger */

/* toastify-custom-styles.css */
.Toastify__toast {
  font-family: "poppins" !important;
  font-weight: 500;
  font-size: 0.8rem;
  /* Change "Your Desired Font" to your chosen font name */
}


@media (min-width: 501px) {
  .mobile-modal {
    display: none;
    /* Hide the modal */
  }
}


.ann-btn {
  background: linear-gradient(90deg, #101828 1.49%, #0A1E46 95.79%) !important;
  color: #fff;
  font-family: var(--secondary-font);
  min-height: var(--size-6);
}

/* @media(max-width : 1000px) {
  .ann-btn {
    min-height: 2.4rem;
  }
} */
.react-datepicker-wrapper{
  width: 100%;
}
.secondary-btn {
  background-color: #b695f8;
  color: #fff;
  font-family: var(--secondary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--primary-font-size);
  height: var(--size-6);
}

.Synkli-logo {
  height: 80px;
  width: 150px;
  scale: 0.8;
}

.secondary-border {
  border: 1px solid var(--secondary-bg-color) !important;
}
.third-border {
  border: 1px solid var(--third-bg-color) !important;
  animation: fillBorder 0.3s forwards;

}

.custom-toast-container {
  z-index: 9999;
}

@keyframes slideIn {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideOut {
  from {
      transform: translateX(0);
      opacity: 1;
  }
  to {
      transform: translateX(100%);
      opacity: 0;
  }
}

.toast-enter {
  animation: slideIn 0.5s forwards;
}

.toast-exit {
  animation: slideOut 0.5s forwards;
}

/* 
@media(min-width : 2000px) {
  .Synkli-logo {
    height: 100px;
    transform: scale(0.90);
    width: 170px;
  }
} */

.driver-popover {
  background: #b695f8 !important;
}

.driver-popover-title {
  font-family: "Poppins" !important;
  color: var(--color-11) !important;
  font-weight: 500 !important;
  font-size: var(--size-2) !important;
}

.driver-popover-arrow {
  color: #fff !important;
  background-color: #fff !important;

}


.driver-popover-description {
  font-family: "Poppins" !important;
  color: #fff;
  font-size: var(--primary-font-size) !important;
  font-weight: 300 !important;
}

/* 
.driver-popover-footer {} */

.driver-popover-progress-text {
  color: #b695f8 !important;
}

/* 
.driver-popover-prev-btn {} */

.saved-signature-container {
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.5rem;
  height: 70px;
  width: 70px;
  position: relative;
}

.remove-signature-icon {
  position: absolute;
  top: -10px;
  /* Adjust this value based on your icon size and desired positioning */
  right: -10px;
  /* Adjust this value based on your icon size and desired positioning */

  /* Adjust the background color as needed */
  border-radius: 50%;

  /* Adjust this value based on your icon size and desired spacing */
  cursor: pointer;
}

/* Make sure the SVG fills the badge */
.remove-signature-icon svg {
  width: 100%;
  height: 100%;

}

.saved-signature-inner {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 0.5rem;
}

.save-signature-item {
  width: 100%;
  /* Full width */
  height: 100%;
  object-fit: contain;
}


/* .pdf-display-container {
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  position: relative;
} */

.pdf-actions {
  position: fixed;


  background-color: var(--third-bg-color);
  top: 92%;
  color: #fff;
  padding: 10px;
  transform: translate(-50%, -50%);
  z-index: 22;
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--secondary-font-size);
}

.sign-pdf-actions {
  position: fixed;
  background-color: var(--third-bg-color);
  /* top: 92%; */
  color: #fff;
  padding: 10px;
  top: 92%;
  left: 35%;
  transform: translate(-50%, -50%);
  /* transform: translate(-50%, -50%); */
  z-index: 22;
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
}

.custom-datepicker input::placeholder {
  /* font-size: 0.75rem; */
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  color: #979797 !important;
}

.custom-datepicker input {
  /* font-size: 0.75rem; */
  /* Set your desired font size for the placeholder text */
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  color: #000000!important;
  margin: 0px!important;
  border-radius: 2px!important;
}
.pdf-container {
  transition: transform 0.3s ease;
}
.card-layout-title-text {
  font-family: var(--secondary-font);
  font-size: 2.3rem;
  font-weight: 600;
}

@media(min-width : 2000px) {
  .card-layout-title-text { font-size: 2.6rem;}
}


@keyframes fillBorder {
  0% {
    border-left-width: 0;
    border-bottom-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }

  25% {
    border-left-width: 2px;
    border-bottom-width: 0;
    border-right-width: 0;
    border-top-width: 0;
  }

  50% {
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-right-width: 0;
    border-top-width: 0;
  }

  75% {
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-right-width: 2px;
    border-top-width: 0;
  }

  100% {
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-right-width: 2px;
    border-top-width: 2px;
  }
}

button.hovered::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #b695f8;
  border-radius: inherit;
  animation: flash 1s infinite alternate;
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-3px); }
  40% { transform: translateX(3px);}
  60% { transform: translateX(-3px); }
  80% { transform: translateX(3px);}
  100% { transform: translateX(0); }
}

.shake-animation {
  animation: shake 0.5s ease-in-out infinite;
}
 .poppins-placeholder .ql-editor::before {
  font-family: 'Poppins', sans-serif !important; /* Apply Poppins font */
}
.ql-editor *{
  font-size: 18px!important;
}

.underline-offset-8{
  text-decoration: underline!important;
}


/* -------------------- Progressbar css ---------------------- */
@keyframes bounce-left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px); /* Adjust the distance as needed */
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes bounce-right {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px); /* Adjust the distance as needed */
  }
  100% {
    transform: translateX(-5px);
  }
}


.delay-active-color {
  animation: delayAnimation 800ms forwards;
}

@keyframes delayAnimation {
  0% {
      opacity: 0.7;
      background-color: #bbbbbb;
  }
  100% {
      opacity: 1;
      background-color: linear-gradient(90deg, #101828 1.49%, #0A1E46 95.79%);
  }
  
}

/* dashboard hide scrollbar css */
.resize-y>div {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.resize-y>div::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.default-inputs input:not([type="checkbox"]):not([type="radio"]):not([type="date"]) {
  height: 3rem;
  padding: 0.5rem; 
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  border-radius: 0.8rem;
  display: block;
  width: 100%;
}


.default-inputs label {
  font-family: var(--primary-font);
  font-weight : var(--primary-font-weight)
}



@property --angle {
	syntax: '<angle>';
	inherits: true;
	initial-value: 0deg;
}

.box {
	--angle: 0deg;

	background: linear-gradient(white, white) padding-box, conic-gradient(from var(--angle), #808177, #17171b, #c4c4c4, #021f36) border-box ;
	border: 2px solid transparent;
/* overflow: hidden; */

	animation: rotate-gradient 2s infinite linear;
}
.box-third {
  --angle: 0deg;
  background: linear-gradient(#101828, #101828) padding-box, conic-gradient(from var(--angle), #b695f8, #b695f8, #faf7ff, #b695f8) border-box;
  border: 6px solid transparent;
  animation: rotate-gradient 1.5s infinite linear;
}

@keyframes rotate-gradient {
	from { --angle: 0deg; }
	to { --angle: 360deg; }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}



/*  new text editor */
/* .demo-wrapper.rdw-editor-wrapper>div.rdw-editor-toolbar{
  position: absolute;
  top: 0px;
  z-index: 999;
}
.demo-wrapper.rdw-editor-wrapper>div.demo-editor.rdw-editor-main{
  margin-top: 60px;
} */


.scroll-container_apps {
  max-height: 200px!important;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

}

.grid-row {
  scroll-snap-align: start;
}

.sticky-column {
  /* Custom styles to make the column sticky from the bottom */
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: calc(100vh - 109%); /* Calculate the position to stick from the bottom */
}

.app-gradient {
  background: rgb(238,174,202);
background: -moz-radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
background: -webkit-radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeaeca",endColorstr="#94bbe9",GradientType=1);
}

.app-gradient-2 {
  background: rgb(240, 234, 237);
background: -moz-radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(232, 235, 240) 100%);
background: -webkit-radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(252, 253, 255) 100%);
background: radial-gradient(circle, rgb(245, 245, 245) 0%, rgb(236, 239, 241) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#DCDCDC",endColorstr="#b695f8",GradientType=1);
}


.custom-toast-success {
  background-color: #b695f8; /* Green background */
  color: white; /* White text */
}

/* Define custom styles for error toasts */
.custom-toast-error {
  background-color: #000; /* Red background */
  color: white; /* White text */
}

.Toastify__progress-bar--success{
  background:linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !important;
}


.Toastify__progress-bar--error{
  /* background:linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55) !important; */
  background: red;
}

.\[mask-image\:linear-gradient\(to_bottom_left\,white\,transparent\,transparent\)\] {
  -webkit-mask-image: linear-gradient(to bottom left, white, transparent, transparent);
  mask-image: linear-gradient(to bottom left, white, transparent, transparent);
}
:where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-outlined.ant-picker-disabled, :where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-outlined[disabled] {
  background-color: rgb(229 231 235) !important;
  border: none !important;
}

.draging-class {
  background-color: rgb(241, 255, 255);
  border: 1px solid var(--third-bg-color) !important;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
  z-index: 999;
}

.draging-class input {
  height: 3rem;
  border-radius: 0.7rem;
}


.black-icon-class svg {
  fill: #000 !important;
  stroke: #000 !important;
}

.public-DraftEditorPlaceholder-inner {
  color: #979797;
  font-size: 0.93rem;
  font-weight: var(--secondary-font-weight);
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: #b695f8 !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #b695f8 !important;
}





/* .drop-card-wrap .drop-card-one,
.drop-card-wrap .drop-card-two,
.drop-card-wrap .drop-card-three , */
.drop-card-animate {
  transition: transform 0.3s ease-out, z-index 0.3s ease-out;
}

/* .drop-card-wrap .drop-card-one {
  transform: rotate(-10deg);
  z-index: 1;
}

.drop-card-wrap .drop-card-two {
  z-index: 5;
}

.drop-card-wrap .drop-card-three {
  transform: rotate(10deg);
  z-index: 1;
} */

/* Animation state during dragging */
.drop-card-animate .drop-card-one {
  transform: rotate(-45deg);
  z-index: 1;
  transition-delay: 0.3s;
  transition: transform 0.3s ease-out, z-index 0.3s ease-out;
}

.drop-card-animate .drop-card-two {
  transform: scale(1.2);
  z-index: 5;
  transition: transform 0.3s ease-out, z-index 0.3s ease-out;
  transition-delay: 0.2s;
}

.drop-card-animate .drop-card-three {
  transform: rotate(45deg);
  z-index: 1;
  transition-delay: 0.3s;
  transition: transform 0.3s ease-out, z-index 0.3s ease-out;
}

.flex.items-center.justify-between.my-1.draggedSelectedItem{
  z-index: 999!important;
}
/* 
.file-drop-zone-container:hover .drop-card-one {
  transform: rotate(-45deg);
  z-index: 1;
}

.file-drop-zone-container:hover .drop-card-two {
  z-index: 5;
  transform: scale(1.2);
}

.file-drop-zone-container:hover .drop-card-three {
  transform: rotate(45deg);
  z-index: 1;
} */

.demo-wrapper.rdw-editor-wrapper{
  height: 200px;
}
.demo-wrapper.rdw-editor-wrapper  .demo-editor.rdw-editor-main{
  height: 90%;
}

.rdw-dropdown-wrapper,.rdw-option-wrapper{
  border: none !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}
.rdw-option-wrapper{
  height: -webkit-fill-available !important;
}
.rdw-dropdown-wrapper:hover,.rdw-option-wrapper:hover{
  background-color: #e5e7eb !important;
}

/* 
.demo-editor.rdw-editor-main {
     height: 50px ;
} */

.demo-toolbar-absolute{
  top: -40px!important;
  right: 0!important;
}
.public-DraftStyleDefault-block{
  margin: 0px!important;
}



.rdw-emoji-modal, .rdw-link-modal{
  left: -200px !important;
}

/* --------- Print Css ---------- */

@media all {
  .page-break {
    display: none;
  }
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    /* -webkit-print-color-adjust: exact;
    color-adjust: exact; */
  }
}
@media print {
  .page-break {
    padding-top: 2rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  padding: 10mm;
}

.react-pdf__Document{
  padding: 1px!important;
}
.rdw-text-align-dropdown,.rdw-list-dropdown{
  z-index: 9!important;
}

.custom-list li::marker {
  font-size: 1.5rem;  /* Make the dot bigger */
  color: #b695f8 !important;     /* Set a custom color (example: pink-400) */
}

/* -------- Editor -------------- */

.jodit-status-bar__item.jodit-status-bar__item-right>.jodit-status-bar-link{
  display: none!important;
}

input[type='radio']{
  border: 1px solid var(--third-bg-color);
  padding: 1px;
}
input[type='radio']:checked {
  background-image: none !important;
  outline: groove;
    outline-offset: 2px;
    outline-width: thin;
    scale: 0.8;
}     