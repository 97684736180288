.p-image {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.business-profile {
    height: 80vh;
}

.p-image .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-size: contain;
    border-radius: 50%;
}

.profile-camera-svg {
    position: absolute;
    position: absolute;
    bottom: 12px;
    /* Adjust the distance from the bottom as needed */
    right: 10px;
    /* Adjust the distance from the right as needed */
    width: 24px;
    /* Set the width of the SVG icon as needed */
    height: 24px;
    /* Set the height of the SVG icon as needed */
    scale: 0.6;
    cursor: pointer;
    /* Add a pointer cursor to the SVG icon */
}

.profile-images {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-info {
    font-family: var(--primary-font);
}

.profile-title {
    font-weight: var(--primary-font);
    color: #000000;
}

.onboarding-title {
    font-weight: var(--bolder-font);
    font-size: var(--size-3-5);
    font-family: var(--secondary-font);
    color: #000000;
}

.profile-flex {
    width: 100%;
}

.profile-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}

.overlay {
    color: #601f55eb;
}
