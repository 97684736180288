.ann-text-form {
  border: 1px solid #979797;
  border-radius: 10px;
  width: 100%;
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(11px, 12px, 14px);
  padding: 5px 10px;
}
.ann-text-form:focus {
  outline: none;
}
/* .add-new-question-btn {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.add-new-question-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 15%;
  min-height: 5vh;
  font-family: "fira sans";
  font-weight: 500;
  font-size: clamp(12px, 13px, 14px);
}
