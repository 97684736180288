.sidebar-close {
    background: var(--primary-bg-color);
    position: fixed;
    left: 0;
    height: 100%;

    width: var(--size-13);
    transition: width 0.4s ease-in;
}

.sidebar-open {
    background: var(--primary-bg-color);
    position: fixed;
    left: 0;
    height: 100vh;
    width: var(--size-25);
    transition: width 0.4s ease-in;
    z-index: 1;
}

.sidebar-logout-btn {
    background: var(--primary-bg-color);
}

.open-logo {
    height: 50px;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 24px;
}

.close-logo {
    height: 25px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 20px;
}

.content {
    flex: 1;
    margin-left: var(--size-13);
    transition: margin-left 0.3s ease-in-out;
    height: 100vh;
}

.content.squeeze {
    margin-left: var(--size-25);
    height: 100vh;
}

.main-title {
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-size);
    font-size: var(--size-2-5);

    /* height: 9%; */

    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-height: 120px !important;
    margin: auto;
    margin-top: 10px;
}

.open-title {
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-size);
    font-size: var(--size-4);
    height: 10%;
    display: flex;
    margin-left: 1.1rem;
    align-items: flex-end;
}

.open-title p {
    letter-spacing: 2px;
}

.dashboard {
    display: flex;
    min-height: 100%;
}

.content::-webkit-scrollbar {
    width: 5px;
}

.content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    width: 5px;
    border-radius: 10px;
}

.content::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
}

.opened-link {
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: var(--secondary-font-size);
}

.inner-app-side {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    transition: width 0.4s ease-in;
}

.toggle-open {
    position: absolute;
    left: 100%;
    top: 2.188rem;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
}

.side-bar-items {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
}

.relations {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #bbb;
    font-size: var(--primary-font-size);
}

.profile-name {
    font-size: var(--secondary-font-size);
}

.hoverable:hover {
    background-color: white;
    color: #000000;
}

.clients {
    position: absolute;
    left: 100%;
    /* top: 20%; */
    width: 15.8rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    z-index: 1;
    transition: display 0.5s ease;
}

.workplace {
    font-family: var(--primary-font);
    /* margin-top: 19rem; */
    top: 320px;
}

.client-widgets {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.settings {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
    height: 4vh;
}

.m-item {
    border-radius: 0.4rem;
}

.m-item:hover {
    background-color: white;
    color: #000;

    cursor: pointer;
}

/* .m-item svg {
  fill: white;
}

.m-item:hover svg {
  fill: black;
} */

.m-item {
    margin-top: 1rem;
}

.sidebar-profile-email {
    font-family: 'poppins';
    font-weight: 300;
    font-size: 9px;
    color: blueviolet;
}

.sidebar-profile-name {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 0.7rem;
}

.sidebar-image-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.setting-side {
    font-family: 'poppins';
    font-weight: 500;
    z-index: 1;
}

.black-paint-order svg {
    paint-order: 'stroke' !important;
}

/* .side-svg-changer svg {
  fill: white;
}

.side-svg-changer:hover svg {
  fill: black;
} */

.activate-tab-sidebar {
    display: none;
}

/* .sidebar-headings {
  height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
} */

.sidebar-headings::-webkit-scrollbar {
    width: 1px;
}

.sidebar-headings::-webkit-scrollbar-thumb {
    background-color: #8e8a8a;
    width: 1px;
    border-radius: 10px;
}

.sidebar-headings::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.app-sidebar {
    z-index: 9;
}

.taskItemSidebar:hover svg {
    filter: invert();
}

@media (max-width: 768px) {
    .app-sidebar {
        position: fixed;
        /* height: 100vh; */
        height: 77vh;
        min-width: 30%;
        top: 0;
        left: 0;
        z-index: 999;
        transition: 0.5s ease-in;
    }

    .open-title {
        margin-left: 1.1rem;
    }

    .sidebar-close {
        display: none;
    }

    .activate-tab-sidebar {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
    }

    .content.squeeze {
        margin-left: 0;
    }

    .content {
        margin-top: 1.3rem;
        margin-left: 0;
    }

    .sidebar-close {
        margin-left: 0;
    }

    .sidebar-open {
        margin-left: 0;
    }
}

.blue-bg {
    background: var(--primary-bg-color);
    color: white;
    width: 100%;
    transition: 0.5s ease-in;
    z-index: 5;
}

.only-icon svg {
    fill: #fff;
}
.only-icon1 svg {
    fill: none !important;
}

.job-icon svg {
    fill: white !important;
    stroke: #ffffff !important;
}

.only-icon {
    /* width: 35px;
    height: 35px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 0.2rem; */
}

.only-icon:hover {
    background-color: #fff;
    cursor: pointer;
    /* padding: 0.3rem !important; */
    /* border-radius: 50%; */
}

.only-icon:hover svg {
    fill: #000;
}

.open-item {
    font-family: var(--primary-font);
    font-weight: 300;
    /* font-size: var(--secondary-font-size); */
    font-size: var(--primary-font-size);
    cursor: pointer;
    margin-left: 1rem;
    padding: 0.2rem;

    margin-right: 1rem;
}

.open-item svg {
    fill: #fff;
}

.open-item:hover {
    background-color: #fff;
    color: #000;
    border-radius: 0.5rem;
}

.open-item:hover svg {
    fill: #000;
}
.open-item:hover .job-icon svg {
    fill: #000 !important;
    stroke: #000000 !important;
}

.job-icon:hover svg {
    fill: #000 !important;
    stroke: #000000 !important;
}
.open-string {
    margin-left: 1.2rem;
}

.profile-sidebar-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.setting-item {
    margin: 0 1rem;
    padding: 0.3rem !important;
    border-radius: 0.5rem;
}

/* .setting-side-wrap {
    height: 90vh;
    overflow-y: auto;
}

.setting-side-wrap::-webkit-scrollbar {
    width: 3px;
    cursor: pointer;
    border-radius: 10px;
}

.setting-side-wrap::-webkit-scrollbar-thumb {
    background-color: #f8f4f4;
    border-radius: 10px;
    cursor: pointer;
}

.setting-side-wrap::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
    cursor: pointer;
} */

.div-disabled {
    pointer-events: none;
    opacity: 0.8;
}

.white-svgs svg {
    fill: #000 !important;
    stroke: url(#paint1_linear_157_2145) !important;
}

.settings-card-item:hover {
    /* background: #b695f8; */
    /* border: 1px solid #b695f8; */
    cursor: pointer;
    /* color: #fff;
    transition: 0.4s ease; */
}
/* 
.settings-card-item:hover svg {
    fill: #fff !important;
    stroke: #fff !important;
} */
