.email-authenticator {
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;

  width: 38%;

  position: relative;
  overflow: hidden;
}

.verify-btn-ab {
  position: absolute;
  bottom: 10%;

}

.authenticator-title h1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
}

.authenticator-text {
  font-family: "Poppins";
  font-weight: 400;
  color: #666666;
}

input {
  font-family: "Poppins";
  font-weight: 500;
}

.verify-section button {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  color: #fff;
  font-family: "Poppins";
}

.verify-section p {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
}







.email-auth .title {
  font-family: var(--secondary-font);
  font-weight: 900;
  font-size: var(--size-4);
  color: var(--primary-bg-color)
}