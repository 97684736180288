.deductions-cards-container {
    display: grid;
    grid-template-columns: 63% 35%;
    gap: 20px;
    /* You can adjust the gap between columns */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .deductions-cards-container {
        grid-template-columns: 100%;
        /* Switch to a single column layout on smaller screens */
    }
}

.summary-body {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #222222;
}

.table-with-toggle {
    background-color: var(--light-color);
}

.trading-income {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: 16px;
}
.disable-btn {
    color: #b5aaaa;
    border-color: #b5aaaa;
}
.summary-bg-color {
    background-color: rgb(220 205 249);
}
.underline-color {
    color: #a889e3;
}
.black-color {
    color: black;
}
