.role-btn {
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    color: #fff;
    padding: 0.6rem 3rem;
    background: var(--primary-bg-color);
}

.role-container {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    overflow-y: auto;
}

.role-container::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.role-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.role-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.hidden-check {
    visibility: hidden;
}

.role-top-border {
    background-color: #444;
    border-bottom: 1px solid #bbb;
    width: 1rem;
}

.border-item-one {
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
    border-left: 1px solid #bbb;
}

.bottom-line {
    width: 1rem;
    border-bottom: 1px solid #bbb;
}

.role-body {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--secondary-font-size);
}

.role-item {
    margin-bottom: -15px;
}

.border-items-wrapper {
    margin-left: 2px;
}

.role-type {
    background-color: #f0d9d9;
    color: #f14747;
    border-radius: 0.5rem;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
    padding: 0.2rem;
}
.delete-all-roles {
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    color: white;
    padding: 0.6rem 2rem;
    background: var(--third-bg-color);
}

.created-time {
    color: #979797;
}
