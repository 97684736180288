.my-threads {
    height: 80vh;
    overflow-y: auto;
}

@media screen and (min-width: 1000px) and (max-height: 649px) {
    .comment-reply-container {
        padding: 0 30px 0 10px;
    }
}

/* 
@media screen and (min-width: 1350px) {
  .my-thread-name {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 80%;
  }

  .my-thread-date {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 60%;
    color: #979797;
  }

  .my-thread-title {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 75%;
  }

  .my-thread-description {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 11px;
  }
} */
