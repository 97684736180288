.dashboard-page {
  position: relative;
  height: 100vh;
  width: 100%;
}

.main-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 0.5rem;
  width: 100%;
}

.main-grid-container .two {
  background-color: white;
  height: 90vh;
  width: 30%;
  border-radius: 10px;
  overflow-y: scroll;
}

.main-grid-container .three {
  background-color: white;
  height: 90vh;
  width: 40%;
  border-radius: 10px;

  overflow-y: scroll;
  overflow-x: hidden;
}

.main-grid-container .two::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}

.main-grid-container .two::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.main-grid-container .two::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.main-grid-container .three::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}

.main-grid-container .three::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.main-grid-container .three::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.main-grid-container .four {
  background-color: #f5f5f5;
}

.bulb {
  background-color: #b695f8;
  font-family: "Poppins";
  font-weight: 500;
}

.two-factor {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  font-family: "Poppins";
  font-weight: 700;
  color: #fff;
  font-size: 10px;
}

.tf {
  border: 1px solid #0d1b37;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 700;
}



.auth {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 700;
}

@media (max-width: 1000px) {
  .main-grid-container {
    display: block;
    margin-left: 0rem;
    margin-top: 3rem;
  }

  .main-grid-container .two {
    height: 35vh;
    margin-bottom: 3rem;
    width: 100%;
    margin: 0;
  }

  .main-grid-container .three {
    margin-top: 1rem;
    width: 100%;
  }
}

.proceed-btns {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  color: #fff;
  font-size: 12px;
  font-family: "fira sans";
}