.lost-login {
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;
  min-height: 85vh;
  width: 38%;

  position: relative;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .lost-login {
    width: 100%;
  }
}
