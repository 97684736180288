.login-questions {
    background-color: #fff;
    border-radius: 15px;
    background-image: var(--primary-bg-color);
    background-position: bottom;
    background-size: 100% 20px;
    background-repeat: no-repeat;
    padding-bottom: 2rem;
    width: 50%;
}

.login-security-questions {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-questions-label {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.question-div {
    border: 1px solid var(--secondary-bg-color);
    width: 100%;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    height: var(--size-6);
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.next-btn button {
    background: var(--primary-bg-color);
    margin-top: 2rem;
    color: #fff;
    height: var(--size-6);
}

@media (max-width: 1000px) {
    .login-questions {
        width: 100%;
        margin: 0;

        margin-top: 1rem;
    }

    /* .login-questions {
    font-size: 1rem;
  }
  .next-btn button {
    font-size: 1.2rem;
    min-height: 6vh;
    padding: 0 8rem;
  } */
}
