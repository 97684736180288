.top-employee-img-wrapper {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}

.top-employee-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}

.top-employee-name {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.top-employee-email {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--size-1-5);
}

.top-emp-task-counter {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--size-1-5);
    color: var(--color-10);
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.grid-container-top-emp {
    display: grid;
    grid-template-columns: 120px 30px;
    align-items: center;
    gap: var(--size-1);
}

.progressBar-top-emp {
    background-color: #b695f8;
    height: 5px;
}