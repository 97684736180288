@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 0 0% 3.9%;
        --foreground: 0 0% 98%;

        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
    }
    .jodit-scope {
        all: unset !important;
    }
}

/* ------------------------------------------- Setting Default CSS of Component  ------------------------------- */
@layer components {
    /*  ------------------------------   Page Title Section ----------------------- */
    /* Headiing */
    .page-title {
        @apply flex text-3xl font-[600] leading-7 sm:truncate sm:text-3xl  tracking-normal;
    }
    .default-font {
        @apply text-base font-[500];
    }
    .default-font-light {
        @apply text-base font-[400];
    }
    .default-font-small {
        @apply text-sm font-[400];
    }
    .focusedElement {
        @apply ring-1 ring-inset ring-third rounded-xl shadow-lg;
    }
    /* .default-forms input {
        @apply text-[0.9rem] font-[400]    h-[3rem]  rounded-[0.7rem] block w-full text-black;
    } */
    /* .default-forms input:not(.comboboxInput) { */
    .default-forms input {
        @apply text-[0.9rem] font-[400]    h-[3rem] placeholder-[#979797]  rounded-[0.7rem] block w-full text-black;
    }
    .default-forms .text-custom {
        @apply text-[0.9rem] font-[400];
    }

    .default-forms label {
        @apply text-base font-[500]      block w-full text-black;
    }

    /* Title Sections Buttons */
    .titleRightButton {
        /* @apply flex gap-2 items-center px-7 py-4 md:px-8 md:py-5 xl:px-9 xl:py-7 text-[14px] lg:text-[16px] xl:text-[18px] !rounded-xl; */
        @apply flex gap-2 items-center px-7 md:px-8 xl:px-9 py-3 text-[14px] lg:text-[16px] xl:text-[18px] !rounded-xl;
    }

    /*  --------------------------------   General Components ----------------------- */
    /* DatePicker */
    .customDatePicker input {
        @apply font-fira !text-gray-900 text-sm lg:text-[14px] px-5;
    }
    /* Table Filter Inputs */
    .customFilters {
        @apply font-fira !text-gray-900 text-sm lg:text-[14px] px-5;
    }
    /* Table Headings */
    .tableHeadings {
        @apply relative font-medium text-[14px] md:text-[16px] lg:text-[18px] py-3.5 sm:px-0;
    }
    .primaryButton {
        @apply text-[12px] md:text-[14px] lg:text-[16px] !font-normal !rounded-xl px-5 py-2;
    }

    .inputLabel {
        @apply text-[14px] md:text-[16px] lg:text-[16px] !font-medium text-black;
    }
    input[type='text'],
    input[type='tel'],
    input[type='number'] {
        @apply border-[#979797] focus:border-gray-600 focus:outline-none focus:ring-0 pl-2;
    }
    input[type='checkbox'] {
        @apply text-[#b695f8] focus:ring-0 focus:outline-none outline-none;
    }

    .text-md2 {
        @apply text-[10px] md:text-[12px] lg:text-[14] xl:text-[16px] font-[500];
    }
    /* heading bigger than table heading used for cards  */
    .card-heading {
        @apply font-fira text-blueish font-[600] md:text-[14px] lg:text-[16px] xl:text-[20px];
    }

    .card-heading-smaller {
        @apply text-blueish font-[600] md:text-[14px] lg:text-[16px] xl:text-[20px];
    }
    .card-heading-two {
        @apply text-blueish text-xl font-[600] font-fira;
    }

    .input-label-max {
        @apply text-[15px] md:text-[15px] lg:text-[16px] xl:text-[17px] font-[500] text-black;
    }

    .small-text {
        @apply text-base font-[400];
    }
    .x-small-text {
        @apply text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] font-[400];
    }
    .table-title {
        @apply text-base font-[500];
    }

    .big-title-lighter {
        @apply text-base font-[400];
    }
    .lg-title {
        @apply text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-[500];
    }
    .xl-title {
        @apply text-[16px] md:text-[18px] lg:text-[20px] xl:text-[24px] font-[500];
    }
    .table-body-font {
        @apply text-[12px] md:text-[13px] lg:text-[15] xl:text-[18px] font-[400];
    }
    .inputs-font {
        @apply text-[0.90rem] font-[400];
    }
    .buttons-font {
        @apply text-base font-[500];
    }

    .buttons-font-lg {
        @apply text-lg font-[500];
    }

    .buttons-font-lighter {
        @apply text-base font-[400];
    }

    .buttons-font-light {
        @apply text-base font-[300];
    }
    .settings-card-item {
        @apply ring-1 ring-white hover:ring-third ring-inset;
    }
    .bg-pulse {
        @apply bg-[#C4C2C2]/15;
    }
}

.customShadow {
    box-shadow: -8px 0px 20px 0px #0000001a !important;
}

.public-DraftEditorPlaceholder-root {
    font-size: 17px !important;
    color: #7c838fe8 !important;
}
.ql-editor.ql-blank::before {
    color: #979797 !important;
    font-size: 16px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    /* font-style: normal !important;
    color: #979797 !important;
    font-size: medium !important; */
}

/* Lazy loader progress */
#nprogress .bar {
    background: var(--third-bg-color) !important;
}
#nprogress .spinner-icon {
    border-top-color: var(--third-bg-color) !important;
    border-left-color: var(--third-bg-color) !important;
}
#nprogress .peg {
    box-shadow: 0 0 10px var(--third-bg-color), 0 0 5px var(--third-bg-color) !important;
}

.inside-fill-white * {
    fill: #ffffff !important;
    stroke: #ffffff !important;
}
.inside-fill-white > rect {
    fill: none !important;
    stroke: none !important;
}
.inside-fill-white .insideColor * {
    fill: #000000 !important;
    stroke: #000000 !important;
}
/* ------------------- Task Description -------------- */
.ql-container.ql-snow {
    border: none !important;
    border-top: 1px solid #ccc !important;
}

.custom-shadow {
    box-shadow: -8px 0px 20px 0px #0000001a;
}
.ruby {
    display: ruby;
}

/* ------------- PDF Viewer ----------------------------- */
.react-pdf__Page__textContent.textLayer {
    margin: auto;
}
/* ------------- Phone Input ----------------------------- */
.react-international-phone-input-container.bg-transparent .react-international-phone-country-selector button,
.react-international-phone-input-container.bg-transparent .react-international-phone-input {
    background-color: transparent !important;
}
.h-\[290px\] {
    height: 290px;
}

@media print {
    .hide-on-print {
        visibility: hidden !important;
        display: none !important;
    }
}
