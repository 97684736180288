.form-preview-btn {
    background-color: #b695f8;
    border-radius: 8px;
    color: #fff;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.form-building-container input {
    border: 1px solid #979797;
}

.form-building-container label {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.top-form-builder input {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    width: 50%;
    height: var(--size-6);
    padding: 0 10px;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.steps-top input {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    width: 100%;
    height: var(--size-6);
    padding: 0 10px;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.steps-bottom input {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    width: 100%;
    height: var(--size-6);
    padding: 0 10px;
}

.steps-bottom select {
    height: var(--size-6);
}

.steps-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.d-form-submit-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);

    font-size: var(--primary-font-size);
    border-radius: 5px;
    height: var(--size-6);
}

.d-form-cancel-btn {
    background-color: #fff;
    border: 1px solid #979797;
    color: #979797;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);

    font-size: var(--primary-font-size);
    border-radius: 5px;
    height: var(--size-6);
}

.form-direction {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

/* .preview-complete {
  background: #b695f8;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  font-size: var(--primary-font-size);
  border-radius: 50%;
  font-family: "Poppins";
} */

.preview-complete {
    background-color: var(--third-bg-color);
    height: 20px;
    width: 30px;
    border-radius: 50%;
    color: #fff;
}

.preview-steps {
    position: relative;
}

.preview-steps::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.preview-steps::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.preview-steps::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.preview-step-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    color: var(--secondary-bg-color);
}

.field-label {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
}

.field-input-wrapper {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #979797;
    height: var(--size-6);
}

.field-input {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    padding: 0 10px;
}

.preview-back-btn {
    border: 1px solid var(--secondary-bg-color);
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #979797;
    height: var(--size-6);
    padding: 0 25px;
}

.preview-next-btn {
    background: var(--primary-bg-color);
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--size-6);
    padding: 0 35px;
    color: #fff;
}

.preview-btns {
    position: absolute;
    bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.field-switch-wrapper {
    width: 100%;
    height: var(--size-6);
    border: 1px solid #979797;
    border-radius: 20px;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #979797;
    display: grid;
    grid-template-columns: 50% 50%;
}

.field-switch-wrapper .active {
    background: var(--primary-bg-color);
    color: #fff;
    height: var(--size-6);
    font-weight: var(--secondary-font-weight);

    border-radius: 20px;
    border: 3px solid #0a1e46;
}

.form-save-button {
    background: var(--primary-bg-color);
    font-weight: var(--primary-font-weight);
    font-family: var(--primary-font);
    font-size: var(--primary-font-size);
    color: #fff;
    width: 120px;
    height: var(--size-6);
    border-radius: 8px;
}

.form-save-draft-button {
    height: var(--size-6);
    border: 1px solid #0d1b37;
    border-radius: 8px;
    width: 120px;
    font-weight: var(--primary-font-weight);
    font-family: var(--secondary-font);
    font-size: var(--primary-font-size);
    color: #0a1e46;
}

.form-cancel-button {
    height: var(--size-6);
    border: 1px solid #979797;
    border-radius: 8px;
    width: 120px;
    font-weight: var(--primary-font-weight);
    font-family: var(--secondary-font);
    font-size: var(--primary-font-size);
    color: #979797;
}

.stripe-option {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 0.8rem;
    color: #0a1e46;
}

.signature-pad {
    border: 1px solid #b695f8;
    border-radius: 0.5rem;
}

.signature-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.signature-clear {
    font-size: var(--primary-font-size);
}

.add-ann-img-form {
    border: 1px dotted #979797;
    background-color: rgba(182, 149, 248, 0.1);
    border-radius: 0.5rem;
    padding: 0.9rem;
}

.selected-file {
    background-color: #b695f8;
    border-radius: 0.5rem;
    color: #fff;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 0.7rem;
    padding: 0.3rem;
    margin-top: 0.5rem;
}

.com-step {
    background-color: var(--third-bg-color);
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.incom-step {
    background-color: var(--secondary-bg-color);
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.form-input-container {
    display: grid;
    grid-template-columns: 20% 33% 32% 12%;
    gap: 1rem;
    /* Adjust the gap as needed */
}

.form-input-child {
    background-color: #ccc;
    /* Set your desired background color */
    padding: 1rem;
    /* Adjust padding as needed */
    text-align: center;
}

/* Responsive styling using media queries */
@media (max-width: 625px) {
    .form-input-container {
        grid-template-columns: 100%;
        /* Switch to a single column layout on smaller screens */
    }
}

.save-step-btn {
    background: var(--primary-bg-color);
    width: 100px;
    height: var(--size-6);
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
    color: #fff;
}

.clear-step-btn {
    border: 1px solid #979797;
    width: 100px;
    height: var(--size-6);
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.preview-form-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding-top: 0.3rem;
}

@media (max-width: 800px) {
    .clients-wrapper-div {
        width: 100%;
        /* Full width */
        overflow-x: scroll;
    }

    .clients-table {
        width: 150%;
    }
}
