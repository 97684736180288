.backup-alternate {
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;
  min-height: 85vh;
  width: 38%;

  position: relative;
  overflow: hidden;
}

.alternate-title h1 {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 33px;
}

.b-label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
}

.b-input input {
  border: 1px solid #bbbbbb;
  width: 75%;
  height: 6vh;
}

.upper-layer-b {
  height: 80%;
}

.bottom-layer-b button {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  font-family: "Fira Sans";
  position: absolute;
  bottom: 5%;
}
.bottom-layer-b button {
  min-height: 6vh;
}

@media (max-width: 768px) {
  .backup-alternate {
    width: 100%;
  }
}
