:root {
    --primary-two: #f8f0e7;
}

.employee-strip-one {
    background-color: var(--primary-two);
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.item1 {
    flex-basis: 25%;
}

.item2 {
    flex-basis: 22%;
}

.item3 {
    flex-basis: 46%;
}

.grid-container2 {
    display: grid;
    grid-template-columns: 40% 35% 25%;
    gap: 10px;
}

/* Responsive layout */
@media screen and (max-width: 1000px) {
    .grid-container2 {
        grid-template-columns: 100%;
    }
    .grid-container {
        grid-template-columns: 100%;
    }
}

/* Responsive layout - makes the grid items stack on top of each other */
@media screen and (max-width: 768px) {
    .grid-item {
        flex-basis: 100%;
    }
}
