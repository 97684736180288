.login-successfull {
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;

  width: 38%;

  position: relative;
  overflow: hidden;
}

.successfull-text {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: clamp(25px, 32px, 40px);
}

.successfull-btn button {
  background: linear-gradient(180.04deg,
      #101828 1.28%,
      #0d1b37 99.81%,
      #0a1e46 99.96%);
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  min-height: 6vh;
}

@media (max-width: 768px) {
  .login-successfull {
    min-width: 100%;
  }
}