.bt-1 label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
}

.bt-1 {
  height: 85vh;
}

.bt-1 input {
  width: 95%;
  height: 6vh;
  border: 1px solid #979797;
  font-size: 12px;
}
.b1-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11px;
  color: #979797;
}

.file-input {
  display: flex;
  width: 20rem;
  height: 100px;

  border: 2px dashed #999;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  color: #485468;
}

.file-input:hover {
  border-color: #666;
}
.file-input__input {
  display: none;
}

.file-text {
  background-image: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  ); /* Replace with your desired gradient colors */
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Poppins";
  font-weight: 700;
}

.bt-1-top {
  height: 92%;
}
