.app-search-wrapper {
  background-color: white;
  width: 30%;
  height: 5vh;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.app-icon {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.app-search {
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.7rem;
  padding: 0 40px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.app-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  min-height: 22vh;
  padding: 20px;
}
.app-card-desc {
  color: #666;
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.65rem;
}
.app-card-heading {
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.7rem;
}
@media (min-height: 790px) {
  .app-card-heading {
    font-size: 1rem;
  }
  .app-card-desc {
    font-size: 1rem;
  }
  .app-search {
    font-size: 1rem;
  }
}

@media (min-height: 900px) {
  .app-card-heading {
    font-size: 1.2rem;
  }
  .app-card-desc {
    font-size: 1.2rem;
  }
  .app-search {
    font-size: 1.2rem;
  }
}
@media (max-width: 840px) {
  .app-search-wrapper {
    width: 50%;
  }
}

.app-cards {
  height: 75vh;
  overflow-y: scroll;
}

.app-cards::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.app-cards::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.app-cards::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

@media (max-width: 1000px) {
  .appointment-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
  }
}
