.date-ranger-picker-wraper {
    position: relative;
}

.date-selector-div {
    height: var(--size-6);
    /* min-width: var(--size-17); */
    display: flex;
    cursor: pointer;
    border: 1px solid var(--secondary-bg-color);
    /* justify-content: center; */
    align-items: center;
    border-radius: 0.7rem;
}

.picker {
    position: absolute;
    z-index: 15;
    top: 101%;
    right: 5%;
}

.sidebarPicker {
    position: absolute;
    z-index: 15;
    top: 101%;
}

.img-view-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.add-hint-container {
    background-color: #fff;
    position: absolute;
    bottom: 101%;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    right: 0.5rem;
    z-index: 2;
}

.hint-arrow {
    background-color: white;
    z-index: 1;
    width: 12px;
    height: 15px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 100%;
    position: absolute;
    top: 100%;
    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%) rotate(45deg);
    /* Rotate the element 45 degrees and center it */
}

.address-modal {
    background-color: red;
}

.pac-container {
    border: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
}

.img-cropper-container {
    height: 70vh;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 990;
    background-color: #fff !important;

    border-radius: 0.5rem;
}

.rotate-icon {
    height: 25px;
    width: 25px;
}

.croper-actions {
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 16;
    transform: translateY(-50%);
}

.croper-icon {
    height: 30px;
    width: 30px;
}

.close-croper {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 16;
}

.close-croper svg {
    fill: #fff !important;
    stroke: #fff !important;
}

.date-range-pick {
    border: 1px solid var(--secondary-bg-color);
    position: absolute;
    left: 1rem;
    top: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: var(--primary-shadow);
    font-family: var(--primary-font) !important;
}
