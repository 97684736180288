.task-detail-main-card {
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
}

.assigned-images-wrapper {
    display: flex;
    margin-left: 15px;
}

.taskImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: -15px;
}

.add-assigned-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 40px;
    margin-left: 1rem;
    width: 40px;
}

.progress-date {
    font-family: var(--primary-font);
    font-weight: 400;
    /* font-size: var(--size-1-5); */
    color: #bbbbbb;
}

.assigned-people-detail {
    font-family: var(--primary-font);
    font-weight: 400;
    color: #979797;
}

.attachment-card {
    background-color: #fbf8f8;
    border-radius: 0.5rem;
}

.attachment-file-container {
    height: 120px;
    width: 200px;
    border-radius: 0.5rem;
    overflow: hidden;
}

.attchement-file {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.attchement-file:hover {
    scale: 1.3;
    transition: 0.5s ease;
    cursor: pointer;
}

.moveModal {
    background-color: white;
    z-index: 10;
    position: absolute;
    top: 89%;
    left: 5%;
    /* transform: translate(-50%, -50%); */
}

.child {
    background-color: #edf6ff;
    color: black;
}

.child:nth-child(1) {
    background-color: #edf6ff;
}

.child:nth-child(2) {
    background-color: #fff4eb;
}

.child:nth-child(3) {
    background-color: #f0ebff;
}

.child:nth-child(4) {
    background-color: #c4b0ee;
}

.child:nth-child(5) {
    background-color: #d8f895;
}

/* Add more nth-child selectors for additional child divs */

.move-to {
    color: #101828;
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
}

.task-user-profile-container {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
    /* Added overflow hidden to hide overflowing animations */
}

.task-user-profile {
    width: 100%;
    /* Both divs take up 100% width on small screens */
    height: 100%;
    object-fit: cover;
}

.task-comment-input-container {
    /* border: 1px solid #dcdcdc; */
    border-radius: 0.3rem;
    height: var(--size-6);
    overflow: hidden;
    position: relative;
    width: 100%;
    /* Added overflow hidden to hide overflowing animations */
}

.task-comment-input {
    padding: 0.3rem;
    margin-right: 2rem !important;
    width: 75%;
    height: 100%;

    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    /* font-size: var(--primary-font-size); */
}

.comment-file-container {
    background-color: #b695f8;
    color: white;
    font-family: 'poppins';
}

.comment-file-name {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.comment-file-size {
    font-family: var(--primary-font);
    font-weight: 300;
    font-size: var(--1-5);
}

.task-comment-data {
    background-color: #f6f8ff;
    padding: 0.5rem;
    border-radius: 0.3rem;
}

.att-actions {
    font-family: var(--primary-font);

    font-weight: 300;
    /* font-size: var(--size-1-5); */
    color: #666;
}

.task-comment-text {
    font-family: var(--primary-font) !important;
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.employees-modal {
    background-color: #fff;
    border-radius: 0.5rem;
}

.files-btn {
    background-color: #b695f8;
    color: #fff;
    border-radius: 0.4rem;
    padding: 0.3rem;
    font-family: var(--primary-font);
    font-weight: 300;
    height: var(--size-6);
}

.checklist-title {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.task-comment-attachment-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
}

.comment-modal {
    padding: 0.5rem;
    border: 1px dotted #ccc;
    background-color: #fff;
    border-radius: 0.5rem;
}

.reply-modal {
    padding: 0.5rem;
    /* border: 1px solid #ccc; */
    background-color: #fff;
    border-radius: 0.5rem;
}

.activities-container {
    height: 70vh;
    overflow-y: auto;
}

.activities-container::-webkit-scrollbar {
    width: 3px;
}

.activities-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    width: 8px;
    border-radius: 10px;
}

.activities-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.pdf-container {
    width: 100%;
    /* Both divs take up 100% width on small screens */
    height: 100%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b695f8;
}

.pdf-file {
    background-color: white;
    display: inline-block;
    border-radius: 0.3rem;
    color: #b695f8;
    font-weight: 1000;
    font-size: var(--size-2-5);
    padding: 0.2rem;
}

.log-date-container {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
    color: #979797;
    border: 1px solid #979797;
    border-radius: 0.4rem;
    padding: 0.3rem;
}

input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
}

.dim-text {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
    color: #979797;
}

.edit-file-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--size-1-5);
}
