/* .team-input-wrapper {
  background-color: #fff;
  border-radius: 1.5rem;
  position: relative;
  width: 100%;
  padding: 0.5rem;
}
.team-input {
  width: 100%;

  padding: 0 40px;
  border-radius: 1.5rem;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}
.team-search-svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
} */

.team-toggle {
    border: 1px solid #bbb;
    border-radius: 20px;
}

.toggle-blue {
    background: var(--primary-bg-color);
    border-radius: 20px;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    color: #fff;
    height: var(--size-6);
    padding: 0.5rem 3rem;
    border: 2px solid #0d1b37;
    box-shadow: 0 0 20px 0 rgba(152, 132, 132, 0.5);
}

.t-t-btn {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    height: var(--size-6);
    color: #bbb;
}

.team-invite {
    background: var(--primary-bg-color);
    color: #fff;
    border-radius: 10px;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-size);
    font-size: var(--primary-font-size);
    padding: 0.5rem;
    height: var(--size-6);
}

@media (max-width: 1000px) {
    .team-search-svg svg {
        transform: scale(1.3);
    }

    /* 
  .team-input {
    font-size: 1rem;
  }

  .toggle-blue {
    font-display: 1rem;
  } */
}

.no-team {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-family: 'poppins';
    font-weight: 500;
    font-size: 0.9rem;
    color: #979797;
}

.search-team {
    border-radius: 1.5rem;
    background-color: #fff;
    position: relative;
    height: var(--size-6);
    overflow: hidden;
}

.search-team input {
    padding: 0.6rem 2.5rem;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--secondary-font-size);
    height: 100%;
    width: 100%;
    object-fit: cover;
}
