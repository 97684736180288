@import '/node_modules/jodit/es5/jodit.min.css';

.jodit-scope .jodit-wysiwyg {
    all: initial;
}

/* .jodit-scope .jodit-add-new-line {
    margin-top: 20px !important;
    background-color: red !important;
} */
/* .jodit-scope .jodit-wysiwyg input[type='hidden'],
.jodit-scope .jodit-wysiwyg br {
    display: none !important;
} */

.jodit-placeholder {
    font-size: 0.9rem !important;
    padding-top: 6px !important;
}
.jodit-scope .jodit-wysiwyg *:not(div):not(br) {
    all: revert;
}

.border-0 .jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
    border: none !important;
}
.jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
    border-color: #979797 !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    overflow: hidden !important;
    width: 100% !important;
}
.jodit-ui-button_variant_primary {
    background: var(--primary-bg-color);
}
/* .jodit-popup__content {
    display: none !important;
} */
