.profile-skeleton {
    animation: loading 1.5s infinite;
}
.profile-skeleton-details {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    
}
.team-details-skeleton-name{
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    height: 2vh;
    width: 120px;
    border-radius: 0.5rem;
    animation: loading 1.5s infinite;
}
.bio-details-skeleton-name {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    height: 2vh;
    width: 100%;
    border-radius: 0.5rem;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
      background-position: -200% 0;
      transform: scale(0.98);
    }
    100% {
      background-position: 200% 0;
      transform: scale(1);
    }
  }