.person-form {
  height: 88vh; /* Set the desired height of the div */

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.person-form::-webkit-scrollbar {
  background-color: #d4cfcf;
  width: 5px;
  border-radius: 5px;
}

.person-form::-webkit-scrollbar-thumb {
  background-color: #979797; /* Replace with your desired color */
  border-radius: 5px;
}

.person-form label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}
.person-form input,
select {
  width: 100%;
  height: 6vh;
  border: 1px solid #979797;
  font-size: 12px;
  padding-left: 5px;
  border-radius: 10px;
  margin-top: 2px;
  font-weight: 400;
}

.heading-add {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
}

.person-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #979797;
  margin-top: 3px;
  margin-left: 8px;
}
