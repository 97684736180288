.users-sign-up {
  background-color: #fff;
  border-radius: 15px;
  background-image: var(--primary-bg-color);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;
  padding-bottom: 2rem;
  width: 40%;
  font-family: var(--primary-font);
}

.set-password-wrap {
  min-height: 85vh;
}

.users-sign-up .title {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: var(--size-4);
  color: var(--primary-bg-color);
}

.user-sign-up-body label {
  font-size: var(--primary-font-size);
  font-weight: var(--primary-font-weight);
}

.confirm-password-wrap {
  position: relative;
  width: 100%;
  height: 3rem;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.8rem;

  overflow: hidden;
  z-index: 1;
}

.confirm-password-wrap input {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
}

.password-wrapper {
  position: relative;

  width: 100%;
  height: 3rem;
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.7rem;
  z-index: 2;
}


.week-password-border {
  border: 1px solid #FF4444 !important;
}

.strong-password-border {
  border: 1px solid #33DA18 !important;
}

.error-msg {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--secondary-font-size);
}

.pass-eyes {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.validation-password {
  width: 100%;
  height: 100%;
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  object-fit: cover;
  border-radius: 0.7rem;
}

.password-validator {
  position: absolute;
  top: 105%;
  padding: 0.5rem;
  background-color: #fff;

  border-bottom-left-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  color: #000;
  width: 100%;
  z-index: 1;
}

.validate-text {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  color: var(--secondary-bg-color);
}

.validate-special {
  color: #000;
}

.profiency {
  position: absolute;
  right: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
}

.week-password {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  color: red;
}

.strong-password {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  color: rgb(53, 219, 95);
}

.medium-password {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  color: rgb(219, 208, 53);
}

.passowrd-input {
  border: 1px solid var(--secondary-bg-color);
  border-radius: 10px;
  height: var(--size-6);
  width: 100%;
  font-size: var(--primary-font-size);
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
}

.check {
  font-style: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  color: var(--secondary-bg-color);
}

.check span {
  color: #0a1e46;
}

.user-btn-1 {
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.6rem;
  padding: 0 3rem;
  color: var(--secondary-bg-color);
  min-height: var(--size-6);
  font-weight: var(--primary-font-weight);
}

.user-btn-2 {
  background: var(--primary-bg-color);
  color: #fff;
  border-radius: 0.6rem;
  padding: 0 2.4rem;
  min-height: var(--size-6);
  font-weight: var(--primary-font-weight);
}

.us-a {
  font-family: var(--primary-font);
  color: var(--secondary-bg-color);
  margin-top: 15px;
}

.us-ab {
  color: #0a1e46;
}

@media (max-width: 1168px) {
  .user-sign-up-body {
    padding: 1rem;
  }
}

@media (max-width: 1000px) {
  .user-sign-up-body svg {
    transform: scale(1.5);
  }

  /* .user-sign-up-body label {
    font-size: 1rem;
  } */

  /* .form input,
  .check,
  .us-a,
  .user-btn-2,
  .user-btn-1 {
    font-size: 1rem;
  } */
  /* .week-password,
  .strong-password {
    font-size: 1rem;
  }

  .validate-text {
    font-size: 1rem;
  } */
}

@media (max-width: 1000px) {
  .users-sign-up {
    width: 100%;
    margin: 1rem 0;
  }
}

.matched-pass {
  position: absolute;
  right: 2.7rem;
  top: 50%;
  transform: translateY(-50%);
}