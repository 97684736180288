.add-ann-btn {
    padding: 0px 10px;
    height: var(--size-6);
}

.ann-date {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    height: var(--size-6);
}

.ann {
    background-color: white;
    border-radius: 10px;
    width: 100%;
}

.ann-list::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.ann-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.ann-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.ann-title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
}

.ann-img-wrapper {
    height: 15vmin;
    width: 22vmin;
    overflow: hidden;
    border-radius: 10px;
}

.ann-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.ann-img:hover {
    scale: 1.3;
    transition: 0.8s ease;
    cursor: pointer;
}

.ann-subtitle {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
}

.announcement {
    background-color: white;
    box-shadow: var(--primary-shadow);
    border-radius: 0.625rem;
    overflow: hidden;
}

.ann-desc {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    /* color: #666666; */
}

.ann-btns button {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    background-color: #b695f8;
    border-radius: 10px;
    color: white !important;
}

.ann-btns a {
    color: #fff;
}

.ann-info {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #666666;
}

.ann-auther {
    color: blueviolet;
}

.border-radius {
    border: 1px solid;
}

.cursor-pointer {
    cursor: pointer;
}

.no-ann {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-ann-text {
    font-family: var(--secondary-font);
    font-size: var(--size-2-5);
    font-weight: var(--primary-font-weight);
    color: #979797;
}

.ann-detail-img-wrapper {
    width: 100%;
    height: 35vh;
}

.ann-detail-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ann-detail-title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
}

.ann-detail-text {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    word-break: break-all;
    color: #666666;
}

.ann-remove-btn {
    border: 1px solid var(--secondary-bg-color);
    color: var(--secondary-bg-color);
    font-family: var(--secondary-font);
}

.add-ann-form input {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 0.7rem;
    width: 100%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    padding: 0.6rem;
    height: var(--size-6);
    margin-top: 5px;
}

.add-ann-form .ant-picker-input input {
    border-radius: 0px !important;
}

.description-wrapper {
    /* border: 1px solid #979797; */
    width: 100%;
    /* height: 15vh; */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.description-area {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.description-area:focus {
    outline: none;
}

.description-svg {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.add-ann-img {
    height: 12vh;
    width: 100%;
    background-color: #e5e0ee;
    border-radius: 0.5rem;
    border: 1px dotted #979797;
    padding: 0.3rem;
}

.add-ann-pdf {
    height: 70px;
    font-size: 10px;
    width: 70px;
    background-color: #b695f8;
    color: white;
    border-radius: 0.5rem;
    border: 1px dotted #979797;
    padding: 0.3rem;
}

.select-news-ann {
    color: #977977;
}

.add-announcement {
    background: var(--primary-bg-color);
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    height: var(--size-6);
}

.date-announcement {
    border: 1px solid #979797;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    height: var(--size-6);
}

.add-ann-form textarea {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    border: 1px solid var(--secondary-bg-color);
    width: 100%;
}

@media screen and (min-width: 1000px) and (max-height: 649px) {
    .description-wrapper {
        border: 1px solid #979797;
        width: 100%;
        /* height: 12vh; */
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;
    }

    .ann-detail-img-wrapper {
        width: 100%;
        height: 30vh;
    }

    .ann-detail-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .ann-detail-title {
        font-family: 'Poppins';
        font-weight: 500;
    }

    .ann-detail-text {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 10px;
        word-break: break-all;
        color: #666666;
    }
}

.datePicker {
    margin-right: 75px;
}

textarea {
    height: 35vh;
}

@media (max-width: 1000px) {
    /* .add-ann-form input,
  .ann-date,
  .ann-title,
  .ann-subtitle,
  .add-ann-form textarea,
  .add-ann-form select {
    font-size: 1rem;
  } */

    /* .add-ann-form label {
    font-size: 1rem;
  } */

    /* .ann-btns button,
  .ann-remove-btn button {
    font-size: 1rem;
  }

  .ann-desc,
  .ann-dates {
    font-size: 0.9rem;
  }

  .ann-by {
    font-size: 0.7rem;
  } */
}

.ann-detail-img-container {
    width: 100%;
    height: 40vh;
    overflow: hidden;
    /* Added overflow hidden to hide overflowing animations */
    border-radius: 0.5rem;
}

.ann-detail-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.ann-detail-image:hover {
    scale: 1.3;
    transition: 0.7s ease;
    cursor: pointer;
}

.latest-ann-img-wrapper {
    width: 80px;
    height: 70px;
    overflow: hidden;
    /* Added overflow hidden to hide overflowing animations */
    border-radius: 0.5rem;
}

.latest-announcement-title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.remove-ann-btn {
    border: 1px solid #979797;
    border-radius: 0.4rem;
    font-family: var(--secondary-font);
    font-weight: var(--secondary-font-weight);
    color: #977977;
}

.edit-ann-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--secondary-font-weight);
    border-radius: 0.4rem;
}

.annDate {
    font-family: 'poppins';
    font-weight: 400;
    font-size: 0.7rem;
    color: #979797;
    border: 1px solid #979797;
    border-radius: 0.5rem;
    height: 35px;
    padding: 0 0.5rem;
    background-color: #f5f5f5;
}

@media (max-width: 1000px) {
    .annDate {
        margin-top: 0.5rem;
    }
}

.view-all-imgs {
    width: 100%;
    overflow: hidden;
    height: 200px;
}

.view-single-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .ann-img-wrapper {
        height: 30vh;
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        margin-top: 2rem;
    }

    .announcement {
        padding-top: 2rem;
    }
}
