.drag-file-container {
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' strokeWidth='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e"); */
    min-height: 130px;
    border: 1px dashed #e8defb;
    padding: 2px;
}

.drag-file-inner-container {
    min-height: 130px;
    background-color: rgba(182, 149, 248, 0.1);
    color: #000;
}

.border-button {
    border: 1px solid var(--secondary-bg-color);
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    height: var(--size-6);
}

.default-chip {
    background-color: var(--primary-bg-color);
    font-family: var(--primary-font);
    font-weight: var(--third-font-weight);
    font-size: 0.7rem;
}

.error-border {
    border: 1px solid red;
}

.atom-input-field {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.blue-border-btn {
    border: 1px solid var(--color-11);
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    height: 3rem;
}

.clear-filter-button {
    background-color: #ebe2e2;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.tripple-switch {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 12px;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);

    height: var(--size-6);
    color: var(--secondary-bg-color);
}

.tripple-switch-child-selected {
    background: var(--primary-bg-color);
    color: #fff;
    border-radius: 12px;
    padding-left: 3.4rem;
    padding-right: 3.4rem;
    transition: 0.5s ease;
}

.tripple-switch-child {
    height: var(--size-6);
    /* border-radius: 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    transition: 0.5s ease;
}

.double-switch {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 12px;
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    height: var(--size-6);
    color: var(--secondary-bg-color);
}

.double-switch-child-selected {
    background: var(--primary-bg-color);
    color: #fff;
    transition: 0.5s ease;
}

.double-switch-child {
    height: var(--size-5);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    transition: 0.5s ease;
}

.help-button {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    color: var(--light-color);
    height: var(--size-5);
    border: 1px solid var(--color-11);
    border-radius: 0.5rem;
}
