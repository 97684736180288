.d-search {
  width: 35%;
}

.d-table-heading {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
}

.d-table-content {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
}

.d-upload-deduction {
  min-height: 15vh;
  background-color: rgb(209, 240, 242);
  border-radius: 0.5rem;
  border: 1px dashed aqua;
}

.add-d-input {
  width: 100%;
}

.d-add-form label {
  font-family: "poppins";
  font-weight: 700;
  font-size: 12px;
}

.d-add-form input {
  border: 1px solid #979797;
  height: 6vh;
  border-radius: 10px;
  font-family: "poppins";
  font-weight: 400;
  font-size: 12px;
}

.d-add-form input:focus {
  outline: none;
}

@media (max-width: 1000px) {
  .d-search {
    width: 40%;
  }

}

.deduction-container {
  height: 70vh;
  overflow-y: scroll;
}


.deduction-container::-webkit-scrollbar {
  width: 5px;
}

.deduction-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  width: 5px;
  border-radius: 10px;
}

.deduction-container::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}