.manager-card-container {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px 0 rgba(239, 238, 238, 0.29);
    width: 100%;
}

.manager-card {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 7px 0 rgba(162, 161, 161, 0.29);
}

.storage-length {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
}

.file-card {
    background-color: #b695f8;
    display: inline-block;
    width: 130px;
    overflow: hidden;
}

.manager-file-content {
    background-color: rgba(182, 149, 248, 0.1);
    padding-right: 7rem;
}

.file-container {
    display: inline-block;
    width: 100%;
}

.file-content {
    background-color: rgb(240, 249, 250);
    width: 50%;
}

.owner-file {
    display: flex;
    justify-content: flex-end;
}

.recent-activity-file-manager {
    height: 80vh;
    overflow-y: scroll;
}

.recent-activity-file-manager::-webkit-scrollbar {
    width: 2px;
    border-radius: 4px;
}

.recent-activity-file-manager::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 4px;
}

.recent-activity-file-manager::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.file-name {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #fff;
}

.file-size {
    font-family: var(--primary-font);
    font-weight: 300;
    color: #dcdcdc;
}

.manager-activity-title {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
}

.file-manager-log {
    border: none !important;
}

.file-manager-date-separator {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    color: var(--color-11);
}

.profile-skeleton {
    animation: loading 1.5s infinite;
}

.team-details-skeleton-name {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    height: 2vh;
    width: 120px;
    border-radius: 0.5rem;
    animation: loading 1.5s infinite;
}

.profile-details-skeleton-name-activity {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    height: var(--size-6);

    width: 220px;
    border-radius: 0.5rem;

    animation: loading 1.5s infinite;
}

.bio-details-skeleton-name {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    height: 2vh;
    width: 100%;
    border-radius: 0.5rem;
    animation: loading 1.5s infinite;
}

.revoke-display {
    color: var(--third-bg-color);
    font-size: var(--primary-font-size);
    font-weight: var(--primary-font-weight);
}

@keyframes loading {
    0% {
        background-position: -200% 0;
        transform: scale(0.98);
    }

    100% {
        background-position: 200% 0;
        transform: scale(1);
    }
}
