.new-pay-run {
    font-family: 'fira sans';
    font-weight: 500;
    font-size: 0.75rem;
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
    height: 5vh;
    border-radius: 5px;
}
.job-maker-btn {
    background-color: #e3e0e0;
    color: #979797;
    font-family: 'fira sans';
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #666666;
    border-radius: 5px;
    height: 5vh;
}

.run-p-tab {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20% 20% 20%;
    overflow: scroll;
    font-family: 'poppins';
    font-weight: 500;
    width: 100%;
    font-size: 0.75rem;
}

.pay-run-head {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: clamp(0.7rem, 0.8rem, 1rem);
}
.pay-details-body {
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(0.7rem, 0.8rem, 1rem);
}
.pay-details-wrapper {
    height: 85vh;
    overflow-y: scroll;
    position: relative;
}
.pay-details-btns {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .new-pay-run {
        height: 3vh;
    }
    .job-maker-btn {
        height: 3vh;
    }
}

@media (max-width: 1000px) {
    .pay-run-head {
        font-size: 100%;
    }
}

.payment-togglers {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 0.75rem;
    color: #979797;
}
.active-payment-btn {
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
    font-family: 'poppins';
    border-radius: 0.5rem;
}

.client-pays label {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 12px;
}
.client-pays input {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 10px;
    border: 1px solid #979797;
    border-radius: 10px;
    height: 6vh;
    width: 100%;
    padding: 0 10px;
    margin-top: 5px;
}
.client-pays textarea {
    border: 1px solid #979797;
    border-radius: 10px;
    width: 100%;
}
.client-btn-toggler {
    font-family: 'poppins';
    font-weight: 400;
    font-size: 0.6rem;
}

.client-pays {
    height: 80vh;
    position: relative;
}
.client-pays-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
.client-pays-btn button {
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
    font-family: 'fira sans';
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.6rem 5rem;
    border-radius: 0.6rem;
}
.strong-label {
    font-family: 'poppins';
    font-weight: 600;
    font-size: 0.85rem;
}
.week-label {
    font-family: 'poppins';
    font-weight: 500;
}
