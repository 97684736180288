.app-skeleton {
  animation: loading 1.5s infinite;
  border-radius: 0.7rem;

}

.app-skeleton-details {
  height: 200px;
  width: 100%;
  border-radius: 0.7rem;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;

}

@keyframes loading {
  0% {
    background-position: -200% 0;
    transform: scale(0.98);
  }

  100% {
    background-position: 200% 0;
    transform: scale(1);
  }
}