.bio-step {
    background-color: white;
    height: 35vh;
    width: 100%;
    border-radius: 10px;
    font-family: var(--primary-font);
    font-size: var(--primary-font-size) !important;
    font-weight: var(--primary-font-weight);
    overflow-y: scroll;
}

.b-h-container {
    background-color: white;
    /* height: 35vh; */
    width: 100%;
    border-radius: 10px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
}

.b-h-1 {
    overflow-y: scroll;
}

.bio-step::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.bio-step::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.bio::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.b-h-1::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.b-h-1::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.b-h-1::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.b-h-container {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
}

@media (max-width: 720px) {
    .b-h-container {
        display: block;
        height: 100vh;
    }
}

.day-opacity {
    opacity: 0.7;
}

.day-icon {
    color: #979797;
    font-size: 17px;
}

.day:hover {
    border: 2px solid #b695f8;
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

.b-h-2 .title {
    font-family: 'Fira Sans';
    font-weight: 700;
    font-size: 18px;
}

.b-h-2 {
    overflow-y: scroll;
}

.b-h-2::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.b-h-2::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.b-h-2::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.b-h-2 .sub-title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
}

.day-time input {
    border: 1px solid #979797;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    /* --tw-ring-color: #b695f8 !important; */
    --tw-ring-color: var(--secondary-bg-color) !important;
}

[type='email']:focus {
    --tw-ring-color: var(--secondary-bg-color) !important;
    --tw-ring-shadow: none !important;
    border: 1px solid var(--secondary-bg-color) !important;
}

.dim-btn {
    background-color: #ebebeb;
    font-family: 'Fira Sans';
    color: #979797;
    font-weight: 400;
}

.top-btn-service button {
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size) !important;
    background: var(--primary-bg-color);
}

.service-title {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
    font-size: var(--size-2);
}

.service-div {
    border: 1px solid #dfdfdf;
}
.service-div:hover {
    border: 2px solid #b695f8;
    border-radius: 10px;
}

.service-scroll,
.s-i-v {
    /* height: 35vh; */
    height: 500px;
    width: 100%;
    overflow-y: scroll;
}

.s-i-v::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.s-i-v::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.s-i-v::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.service-scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.service-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.service-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.service-active {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
}

.service-active button {
    color: #33da18;
    background-color: #c4fabc;
    border-radius: 10px;
}

.service-text {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    /* font-size: var(--primary-font-size) !important; */
    font-size: 16px;
    color: var(--color-9);
}

.service-time {
    background-color: #b695f8;
    border-radius: 13px;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: #fff;
}

.service-modal-icon {
    fill: #979797;
}

.service-modal {
    background-color: white;

    border-radius: 10px;
}

.service-icon {
    fill: #979797;
}

.modal-div:hover {
    background-color: #b695f8;
    color: #fff;
    cursor: pointer;
}
.modal-div:hover .service-icon {
    fill: white;
}

.s-i-v .title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 20px;
}

.charge-title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    /* font-size: var(--primary-font-size) !important; */
    font-size: 16px;
    color: #979797;
}

.charge {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size) !important;
}

.service-details .title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    /* font-size: var(--primary-font-size) !important; */
    font-size: 16px;
}

.service-details {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    /* font-size: var(--primary-font-size) !important; */
    font-size: 16px;
}

.branch-locations {
    background-color: white;
    border-radius: 10px;
    height: 35vh;
    width: 100%;
}

.location-grid {
    display: grid;
    grid-template-columns: 30% 70%;

    width: 100%;
}

.location-grid .title {
    font-family: var(--secondary-font);
    font-weight: var(--bolder-font);
    font-size: var(--size-2);
}

.location-sub-title {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    /* font-size: var(--primary-font-size); */
    font-size: 18px;
}

.branch-locations {
    overflow-y: scroll;
}

.location-info {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    /* font-size: var(--primary-font-size); */
    font-size: 16px;
    color: var(--secondary-bg-color);
}

.locations-left {
    overflow-y: scroll;
}

.locations-left::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.locations-left::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.locations-left::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.branch-locations::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.branch-locations::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
}

.branch-locations::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

@media (max-width: 950px) {
    .location-grid {
        display: block;
    }
}

/* 
@media screen and (min-width: 1000px) and (max-height: 649px) {}

*/
.my-bio-btn {
    font-family: 'fira sans';
    font-weight: 400;
    font-size: 0.8rem;
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
}
.image-icons-container {
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
