.loader-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.4);

  opacity: 1;
}

.app-loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.app-loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #101828 transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.app-loader-wrapper {

  width: 100%;
  /* Both divs take up 100% width on small screens */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.6);

  opacity: 1;
}

/* 
loader two */
.lds-dual-ring {

  width: 20px;
  height: 20px;

}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* 
loader two */
.lds-dual-ring-white {

  width: 20px;
  height: 20px;

}

.lds-dual-ring-white:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 0.5s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* loader three */

.loader-two {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: #fff transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation-two 0.5s linear infinite;
}

@keyframes rotation-two {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* .inner-right-loader {

    width: 100%;
   position: fixed;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

   z-index: 8;
  background-color:  rgba(255, 255, 255, 0.6);
} */