.email-auth-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.email-auth-grid {
  display: grid;
  grid-template-columns: 20% 25% 35% 18%;
}

.email-auth-grid .one {
  background-color: #f5f5f5;
}

.email-auth-grid .two {
  background-color: white;
  height: 88vh;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
}

.email-auth-grid .three {
  background-color: white;
  height: 88vh;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 10px;
  margin-left: 10px;
  width: 100%;
}

.email-auth-grid .four {
  background-color: #f5f5f5;
}

.arrow-wrapper {
  font-weight: 300;
}

.google-text {
  font-family: var(--primary-font);
  font-weight: 500;
}

.google-inner-text {
  font-family: var(--primary-font);
  color: var(--color-9);
}

.code {
  font-family: var(--primary-font);
  font-weight: 600;
}

.resend {
  font-family: var(--primary-font);
  font-size: var(--secondary-font-size);
}

.center-div {
  margin-left: 5rem;
  margin-right: 5rem;
}

.btn-wrapper {
  height: 35%;
}

.btn-wrapper button {
  background: var(--primary-bg-color);
  color: #fff;
  font-family: var(--primary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--secondary-font-size);
}

@media (max-width: 1000px) {
  /* 
  .google-text,
  .google-inner-text {
    font-size: 1rem;
  } */
}