.leave-schedule-btn {
  border: 1px solid #979797;
  border-radius: 0.5rem;
  min-height: 5vh;
  color: #979797;
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0 1rem;
}
.leave-card {
  background-color: white;
  width: 100%;
  min-height: 25vh;
  border-radius: 0.5rem;
}
.leave-card-text {
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.7rem;
  color: #666;
}
.leave-text-container {
  border: 1px solid #979797;
  border-radius: 0.5rem;
  width: 100%;
  min-height: 20vh;
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.75rem;
  position: relative;
  overflow: hidden;
}
.leave-text-area {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.5rem 1rem;
}
.leave-text-area:focus {
  outline: none;
}
.leave-text-icon {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}
.no-leave-title {
  font-family: "poppins";
  font-weight: 500;
  font-size: 0.8rem;
  color: rgb(21, 2, 35);
}
