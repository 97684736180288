.appointment-subject-textarea textarea {
  border: 1px solid #979797;
  border-radius: 10px;
  width: 100%;
  min-height: 30%;
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(0.7rem, 0.75rem, 1em);
  padding: 5px 10px;
}
.appointment-subject-textarea textarea:focus {
  outline: none;
}
/* .color-picker-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 6vh;
    border-radius: 8px;
    border: 1px solid #979797;
  }
   */
/* 
.color-display {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(0.7rem, 0.75rem, 1rem);
}  */

.ann-email-input {
  border: 1px solid #979797;
  min-height: 6vh;
  border-radius: 8px;
  width: 100%;
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(0.7rem, 0.75rem, 1rem);
  padding: 0 5px;
}
