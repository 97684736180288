.remove-qr-btn {
  color: #979797;
}

.create-code-btn {
  background: var(--primary-bg-color);
  color: #fff;
  font-family: var(--secondary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  height: var(--size-6);
}

.remove-code-btn {
  font-family: var(--secondary-font);
  font-weight: var(--primary-font-weight);
  font-size: var(--primary-font-size);
  color: #979797;
  border: 1px solid #979797;
  padding: 0.5rem;
  height: var(--size-6);
}

.qr-codes-container {
  height: 75vh;
  overflow-y: scroll;
}

.qr-codes-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.qr-codes-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.qr-codes-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.qrcode-wrapper {
  background-color: white;
  border-radius: 10px;
}



.code-branch-name {
  font-family: var(--secondary-font);
  font-weight: var(--bolder-font);
  font-size: var(--primary-font-size);
  color: #000;
  padding-bottom: 10px;
}

@media (max-width: 1000px) {

  /* .create-code-btn,
  .code-branch-name,
  .remove-code-btn {
    font-size: 1rem;
  } */
  .qr-codes-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}