.add-document-title {
  font-family: var(--secondary-font);
  font-weight: var(--bolder-font);
  font-size: var(--xl-font);
  color: var(--color-11);
}

.sub-title-text {
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: 0.65rem;
  color: var(--dim-color);
}

.pdf-actions-container {
  background-color: #fff;
}

.uneditable-data {
  background-color: var(--seventh-bg-color);
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--secondary-font-size);
}

.signature-right-container {
  width: 100%;
  /* Full width */
  height: 70vh;
  overflow-y: auto;

  /* overflow-y: scroll; */
}

.signature-right-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

.signature-right-container::-webkit-scrollbar-thumb {
  background: var(--primary-bg-color);
  border-radius: 10px;
}

.signature-right-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.selected-signee-container {
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.5rem;

}

.select-users-select-container {
  border: 1px solid var(--secondary-bg-color);
  border-radius: 0.5rem;
  font-family: var(--primary-font);
  font-weight: var(--secondary-font-weight);
  font-size: var(--secondary-font-size);
  color: var(--secondary-bg-color);
  height: var(--size-6);
}

/* @media (min-width: 798px) {
  .request-signature-signers-container {
    position: fixed;
    width: 39%;
    transition: 0.3s ease;
  }

  .request-signature-signers-container-closed {
    position: fixed;
    width: 33%;
    transition: 0.3s ease;
  }
} */