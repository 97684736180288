.login-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    color: black;
}

.login-welcome {
    color: var(--color-9);
    width: 100%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.login-step-1 {
    background-color: #fff;
    border-radius: 15px;
    background-image: var(--primary-bg-color);
    background-position: bottom;
    background-size: 100% 20px;
    background-repeat: no-repeat;
    width: 40%;
}

.login-body {
    padding-left: 2rem;
    padding-right: 2rem;
}

.login-form label {
    font-weight: var(--primary-font-weight);
}

.login-form input {
    border: 1px solid var(--secondary-bg-color);
    border-radius: 10px;
    height: var(--size-6);
    width: 100%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.check {
    font-style: var(--primary-font);
    color: var(--secondary-bg-color);
}

.remember-check {
    font-style: var(--primary-font);
    color: var(--secondary-bg-color);
    font-weight: var(--secondary-font-weight);
}

.forgot-pass {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: var(--color-11);
}

.login-btn button {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
    min-height: var(--size-6);
}

.signup-redirect {
    color: var(--color-11);
    cursor: pointer;
}

.dont-have-account {
    color: var(--secondary-bg-color);
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.login-remember {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--size-1-5);
    color: var(--secondary-bg-color);
}

@media (max-width: 1000px) {
    .login-step-1 {
        width: 100%;
        margin: 0;
        margin-top: 1rem;
    }

    /* .login-form label,
  .login-form input,
  .remember-check,
  .forgot-pass,
  .dont-have-account {
    font-size: 1rem;
  }

  .login-body {
    padding: 0 2rem;
  }

  .login-btn button {
    font-size: 1.2rem;
  } */
}

.passowrd-input-wrap {
    border-radius: 0.5rem;
    height: var(--size-6);
    overflow: hidden;
    width: 100%;
    position: relative;
}

.passowrd-input {
    border: none !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.password-eye {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}
