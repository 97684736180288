.set-success {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  background-image: var(--primary-bg-color);
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;
  height: 63vh;
  overflow: auto;
  width: 40%;

  font-family: var(--primary-font);

}

.reset-success {
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-title {
  font-size: var(--size-4-5);
  font-weight: 700;
  font-family: var(--secondary-font);
  margin-top: 15px;
}

.s-text {
  font-family: var(--primary-font);
  font-weight: var(--primary-font-size);
  font-weight: var(--primary-font-weight);
}

.s-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-btn-wrapper button {
  background: var(--primary-bg-color);
  color: #fff;
  font-family: var(--primary-font);
  min-height: var(--size-6);
}

@media (max-width: 800px) {
  .set-success {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
  }

  /* .s-btn-wrapper button {
    font-size: 1.2rem;
  } */
}