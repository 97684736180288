/* .scroler-component::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.scroler-component::-webkit-scrollbar-thumb {
    background: var(--primary-bg-color);
    border-radius: 10px;
}

.scroler-component::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
} */

.percentage-used {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
}

.percentage-title {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
}

.percentage-text {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: var(--secondary-bg-color);
}

.file-created_at {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: var(--color-9);
}

.circular-progress {
    width: 200px;
    height: 200px;
}

.folders-card {
    background-color: #fff;
    border-radius: 0.5rem;
    /* box-shadow: 0 0 7px 0 rgba(162, 161, 161, 0.29); */
}

.file-text {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font);
    font-size: var(--secondary-font-size);
    color: var(--secondary-bg-color);
}

.default-search-input {
    background-color: #fff;
    border-radius: 1.3rem;
    display: inline-block;

    overflow: hidden;
    position: relative;
}

.default-search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
}

.default-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
}

.user-profile-chip {
    background-color: var(--third-bg-color);
    border-radius: 0.7rem;
}

.user-profile-chip-name {
    font-family: var(--primary-font);
    font-weight: var(--third-font-weight);
}

.user-profile-modal-email {
    font-family: var(--primary-font);
    font-weight: var(--third-font-weight);
    color: var(--secondary-bg-color);
}

.user-profile-chip-email {
    font-family: var(--primary-font);
    font-weight: var(--third-font-weight);
    /* color: #fff; */
}

.default-profile-icon-wrapper {
    background: var(--primary-bg-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.default-search-input {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    color: var(--secondary-bg-color);
    font-size: clamp(0.6875rem, 0.5652rem + 0.3vw, 1.25rem);

    display: flex;
    align-items: center;
}

.select-modal-wrapper {
    background-color: #fff;
    width: 100%;
    /* Full width */
    position: absolute;
    top: 102%;
    left: 0;
    z-index: 20;
    right: 0;
}

.user-profile-modal-firstName {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
    color: #000 !important;
}

.select-modal-user:hover {
    background-color: #f8ebeb;
    transition: 0.5s ease;
    cursor: pointer;
}

.images-stack-container {
    width: 30px;
    height: 30px;

    border-radius: 50%;
}

.images-stack-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.user-chip {
    position: absolute;
    bottom: 105%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.point-down-chip {
    background-color: var(--third-bg-color);
    width: 10px;
    height: 10px;

    position: absolute;
    top: 110%;
    position: absolute;

    /* Center vertically */
    left: 50%;
    /* Center horizontally */
    transform: translate(-50%, -50%) rotate(45deg);
    /* Rotate the element 45 degrees and center it */
}

.task-card-image-default {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.task-card-image-after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -15%;
}

/* Add these styles in your Tailwind CSS stylesheet */

.custom-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
}

.custom-tooltip-bottom-arrow {
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent #000 transparent;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.custom-tooltip-top-arrow {
    border-width: 0.5rem 0.5rem 0 0.5rem;
    border-color: #000 transparent transparent transparent;
    top: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.custom-tooltip-left-arrow {
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-color: transparent #000 transparent transparent;
    top: 50%;
    left: -0.5rem;
    transform: translateY(-50%);
}

.custom-tooltip-right-arrow {
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-color: transparent transparent transparent #000;
    top: 50%;
    right: -0.5rem;
    transform: translateY(-50%);
}

.signees-images-container {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
}

.signee-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.menu-user-name {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
}

.menu-user-email {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--third-font-size);
    color: #979797;
}

.remain-number-badge {
    position: absolute;
    background-color: var(--third-bg-color);
    color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5px;
    right: -5px;
    font-family: var(--primary-font);
    font-weight: var(--third-font);
    font-size: 0.5rem;
}

.positive-text {
    color: var(--positive-color);
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.negitive-text {
    color: var(--mid-negitive);
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.modal-label {
    font-family: var(--primary-font);
    font-size: var(--lg-font);
    font-weight: var(--primary-font-weight);
}

.modal-body {
    font-family: var(--primary-font);
    font-size: var(--primary-font-size);
    font-weight: var(--secondary-font-weight);
}

.name-chip {
    position: absolute;
    top: 103%;
    background-color: var(--third-bg-color);
    color: white;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
    border-radius: 0.5rem;
    padding: 0.2rem;
}

.grid-card {
    background-color: #fff;
    border-radius: 0.6rem;
    width: 180px;
}

.grid-card:hover {
    background-color: #eceaea;
    transition: 0.4s ease;
    cursor: pointer;
}

.default-log-file-container {
    width: 100px;
    height: 50px;
    border-radius: 0.5rem;
    overflow: hidden;
}

.default-log-file {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recent-activity-title {
    font-family: var(--secondary-font);
    font-weight: 600;
}

.sort-users {
    border: 1px solid var(--secondary-bg-color);
    height: var(--size-6);
    color: var(--secondary-bg-color);
}

.pdf-actions-global {
    position: sticky;

    background-color: var(--third-bg-color);
    top: 92%;
    left: 50%;
    color: #fff;
    padding: 10px;
    transform: translate(-50%, -50%);
    z-index: 22;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--secondary-font-size);
    width: fit-content;
}
