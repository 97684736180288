.oso-input-container {
    background-color: white;
    height: var(--size-6);
    border-radius: 1.2rem;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.oso-input {
    width: 100%;
    height: 100%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.oso-search-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.oso-add-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.osj-modal {
    background-color: white;
    border-radius: 5px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
}

.osj-modal-child {
    color: #979797;
}

.osj-modal-child:hover {
    background-color: #b695f8;
    color: white;
}

.dynamic-status-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.dynamic-status-table {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.add-status-container {
    height: 75vh;
    overflow-y: scroll;
}

.add-status-container::-webkit-scrollbar {
    height: 1px;
    width: 3px;
}

.add-status-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    height: 3px;
    width: 3px;
    border-radius: 10px;
}

.add-status-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.task-type-container {
    display: flex;
    overflow: scroll;
    flex-direction: column;
}

.task-type-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.task-type-table {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.app-date-picker {
    border: 1px solid #979797;
    border-radius: 0.5rem;
    font-family: 'poppins';
    font-weight: 400;

    color: #979797;
    padding: 0.5rem 1rem;
    font-size: 11px;
    cursor: pointer;
    position: relative;
}

.date-picker-point {
    position: absolute;
    top: 50% !important;
    right: 0.5rem;
    transform: translateY(-50%);
}

@media (max-width: 1000px) {
    /* .dynamic-status-heading,
  .dynamic-status-table {
    font-size: 1rem;
  }

  .oso-input,
  .osj-modal,
  .oso-add-btn {
    font-size: 1rem;
  } */
}

.task-status {
    border: 1px solid #979797;
    border-radius: 0.5rem !important;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 0.8rem;
}

/* ------------------------------------------------------------- */

.oso-input-container {
    background-color: white;
    height: var(--size-6);
    border-radius: 1.2rem;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.oso-input {
    width: 100%;
    height: 100%;
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.oso-search-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.oso-add-btn {
    background: var(--primary-bg-color);
    color: #fff;
    font-family: var(--secondary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.osj-modal {
    background-color: white;
    border-radius: 5px;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
}

.osj-modal-child {
    color: #979797;
}

.osj-modal-child:hover {
    background-color: #b695f8;
    color: white;
}

.dynamic-status-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.dynamic-status-table {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.os-react-switch-checkbox {
    height: 0;
    width: 0;
    display: none;
}

.react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.os-react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
    width: 60px;
}

.add-status-container {
    height: 75vh;
    overflow-y: auto;
}

.add-status-container::-webkit-scrollbar {
    height: 1px;
    width: 3px;
}

.add-status-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    height: 3px;
    width: 3px;
    border-radius: 10px;
}

.add-status-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.task-type-container {
    display: flex;
    overflow: scroll;
    flex-direction: column;
}

.task-type-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size);
}

.task-type-table {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

.app-date-picker {
    border: 1px solid #979797;
    border-radius: 0.5rem;
    font-family: 'poppins';
    font-weight: 400;

    color: #979797;
    padding: 0.5rem 1rem;
    font-size: 11px;
    cursor: pointer;
    position: relative;
}

.date-picker-point {
    position: absolute;
    top: 50% !important;
    right: 0.5rem;
    transform: translateY(-50%);
}

@media (max-width: 1000px) {
    /* .dynamic-status-heading,
  .dynamic-status-table {
    font-size: 1rem;
  }

  .oso-input,
  .osj-modal,
  .oso-add-btn {
    font-size: 1rem;
  } */
}

.task-status {
    border: 1px solid #979797;
    border-radius: 0.5rem !important;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-family: 'poppins';
    font-weight: 400;
    font-size: 0.8rem;
}
