.codes-container {
  background-color: #fff;
  border-radius: 15px;
  background-image: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  background-position: bottom;
  background-size: 100% 20px;
  background-repeat: no-repeat;
  width: 50%;
  font-family: "Poppins";
}

.codes-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
}

.codes-container h1 {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 30px;
}

.backup-text {
  font-family: "Poppins";
  font-weight: 400;
  color: #666666;
}

.main-codes {
  font-family: "Poppins";
  font-weight: 500;
}

.copy-btn,
.download-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  font-family: "Fira Sans";
  color: #fff;
}

@media (max-width: 700px) {
  .codes-container {
    width: 100%;
    padding-bottom: 2rem;
  }
}


.back-to-login-btn {
  border : 1px solid #979797;
  border-radius: 0.5rem;
  font-family: 'fira sans';
  font-weight: 500;
  font-size: 0.8rem;
}