.docs-proof .main-heading {
  font-family: "Poppins";
  font-weight: 500;
  color: #2a2a2a;
  font-size: 12px;
}

.docs-proof {
  height: 80vh;
}

.docs-proof-inner {
  height: 95%;
}

.check-form {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}

.proof-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #979797;
}

.proof-checklistitems {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}

.uploaded-img {
  height: 100px;
  width: 100px;
  overflow: hidden;
}

.up-container {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 10px;
  border: 1px solid #030816;
  border-radius: 10px;
}

.upper-upload {
  height: 70vh;
}

.blocked-btn {
  background-color: #979797;
  font-family: "Fira Sans";
}

.abn-title {
  font-family: "Fira Sans";
  font-weight: 700;
  font-size: 12px;
}

.abn input {
  width: 100%;
  height: 6vh;
  border: 1px solid #979797;
  font-size: 12px;
}

.abn-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: #979797;
}

.p-d-upload {
  height: 13vh;
  font-size: 10px;
}

.p-d-text {
  font-size: 11px;
}

.upload-front {
  font-family: "Poppins";
  font-weight: 500;
  color: #979797;
  font-size: 11px;
}

.payment-text {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 11px;
}

.payment-inputs label {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;
}

.payment-inputs input {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #979797;
  border-radius: 10px;
  padding-left: 5px;
  height: 6vh;
  width: 100%;
  margin-top: 5px;
}

.payment-inner {
  height: 72vh;
}