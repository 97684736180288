.stp-select-date-btn select {
  height: 5vh;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f5f5f5;
  font-family: "poppins";
  font-weight: 500;
  font-size: clamp(70%, 80%, 100%);

  color: #979797;
}
.stp-finalised-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  font-family: "fira sans";
  font-weight: 500;
  font-size: clamp(70%, 80%, 100%);
  height: 5vh;
  margin-top: 3%;
  border-radius: 8px;
  padding: 0 5%;
}
.stp-body {
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(70%, 75%, 100%);
}

.stp-table-save-btn {
  border: 1px solid #979797;
  border-radius: 8px;
  font-size: "fira sans";
  font-weight: 500;
  font-size: clamp(70%, 80%, 100%);
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.stp-table-finalize-stp-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  border-radius: 8px;
  font-size: "fira sans";
  font-weight: 500;
  font-size: clamp(70%, 80%, 100%);
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

@media (max-width: 800px) {
  .stp-select-date-btn select,
  .stp-finalised-btn,
  .stp-table-save-btn,
  .stp-table-finalize-stp-btn {
    height: 4vh;
    font-size: 100%;
  }
}
@media (min-width: 1400px) {
  .stp-select-date-btn select,
  .stp-finalised-btn,
  .stp-table-save-btn,
  .stp-table-finalize-stp-btn {
    font-size: 100%;
  }
  .stp-body {
    font-size: 100%;
  }
  .stp-heading label {
    font-size: 100%;
  }
  .job-search-input {
    font-size: 100%;
  }
}

.summary-item {
  font-family: "poppins";
  font-weight: 400;
  font-size: 0.75rem;
}
.year-summary-container {
  height: 85vh;
  overflow-y: scroll;
}
.year-summary-container::-webkit-scrollbar {
  height: 1px;
  width: 3px;
}

.year-summary-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  height: 3px;
  border-radius: 10px;
}

.year-summary-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
