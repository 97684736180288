.multiselect-card-container {
    position: absolute;
    top: 100%;
    height: 40vh;
    overflow-y: scroll;
    background-color: white;
    z-index: 20;
}

.multiselect-object {
    border-radius: 0.5rem;
}

.multiselect-object-container {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--primary-font-size) !important;
}

.user-details-card {
    background-color: #fff;
    border-radius: 0.5rem;

}

.detail-card-title {
    font-family: var(--secondary-font);
    font-weight: var(--bolder-font);

    color: var(--color-11);
}

.detail-card-key {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
}

.detail-card-value {
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
}

.default-table-heading {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);

}


.expense-calculation {
    font-family: var(--primary-font);
    font-weight: var(--primary-font-weight);
    font-size: var(--bigger-font-size);
}

.total-expense-title {
    font-family: var(--primary-font);
    font-weight: var(--bolder-font);
    font-size: 0.9rem;

}

.date-displayer {
    border: 1px solid #DCDCDC;
    border-radius: 0.5rem;
    height: var(--size-6);
    font-family: var(--primary-font);
    font-weight: var(--secondary-font-weight);
    font-size: var(--primary-font-size);
}

@media(max-width : 800px) {
    .detail-card-key, .detail-card-value {
        font-size: var(--secondary-font-size);
    }
}

.summary-btn {
    border: 1px solid var(--color-11);
}