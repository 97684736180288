.edit-email-template-cards {
  height: 80vh;
  background-color: #fff;
  border-radius: 1%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.edit-email-template-card-one {
  height: 100%;
  overflow-y: scroll;
}

.edit-email-template-card-two {
  height: 100%;
  overflow-y: scroll;
  background-color: white;
}

.edit-email-template-card-one::-webkit-scrollbar {
  height: 1px;
  width: 3px;
}

.edit-email-template-card-one::-webkit-scrollbar-thumb {
  background-color: #ccc;
  width: 3px;
  border-radius: 10px;
}

.edit-email-template-card-one::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.edit-email-template-card-two::-webkit-scrollbar {
  height: 1px;
  width: 3px;
}

.edit-email-template-card-two::-webkit-scrollbar-thumb {
  background-color: #ccc;
  width: 3px;
  border-radius: 10px;
}

.edit-email-template-card-two::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.muted-text {
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(9px, 10px, 12px);
  color: #979797;
}
.booking-modal {
  background-color: #fff;
  border-radius: 1%;
  color: #979797;
}
.booking-modal-row {
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(11px, 12px, 13px);
}
.booking-modal-row:hover {
  background-color: #b695f8;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .edit-email-template-cards {
    display: block;
  }
}

.edit-email-save-btn {
  background: linear-gradient(
    180.04deg,
    #101828 1.28%,
    #0d1b37 99.81%,
    #0a1e46 99.96%
  );
  color: #fff;
  font-family: "fira sans";
  font-weight: 500;
  font-size: clamp(12px, 13px, 15px);
  border-radius: 8px;
  min-height: 6vh;
  padding: 0 15%;
}

.edit-email-send-email-btn {
  background-color: #b695f8;
  color: #fff;
  font-family: "fira sans";
  font-weight: 500;
  font-size: clamp(12px, 13px, 15px);
  min-height: 6vh;
  padding: 0 10%;
  border-radius: 8px;
}
.second-card-muted-text {
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(9px, 10px, 12px);
  color: #666666;
}
.email-tokens-heading {
  font-family: "poppins";
  font-weight: 500;
  font-size: clamp(12px, 13px, 15px);
}
.email-tokens-body {
  font-family: "poppins";
  font-weight: 400;
  font-size: clamp(11px, 12px, 14px);
}
