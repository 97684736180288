.services-cards {
    background-color: white;
    height: 80vh;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
}

.service-title {
    font-family: 'poppins';
    font-weight: 500;
    /* font-size: clamp(10px, 13px, 15px); */
    font-size: 20px;
}

.add-service-btn {
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
    font-family: 'fira sans';
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    border-radius: 5px;
    height: 35px;
    padding: 20px 10px;
}

.services-list {
    height: 100%;
    overflow-y: scroll;
}

.services-list::-webkit-scrollbar {
    height: 1px;
    width: 3px;
}

.services-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    width: 3px;
    border-radius: 10px;
}

.services-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.service-inner-title {
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(10px, 13px, 14px);
}

.service-description {
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 13px);
    color: #666;
}

.service-strip {
    background-color: #b695f8;
    color: #fff;
    border-radius: 5px;
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 13px);
    padding: 0.5rem;
}

.charges {
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    color: #979797;
}

.single-description {
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 14px);
}

.editor-file {
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 14px);
    background-color: #b695f8;
    color: #fff;
    min-height: 3vh;
    padding: 0 5px;
    border-radius: 7px;
}

.editor-toolbar select {
    border: 1px solid #979797;
    border-radius: 10px;
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 14px);
    color: #979797;
    height: 5vh;
    padding: 0 50px;
}

.add-service-boundary {
    height: 85vh;
    overflow-y: scroll;
}

.duration-btn {
    border: 1px solid #979797;
    border-radius: 10px;
    height: 70px;
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    align-items: center;
    padding: 0 10px;
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 14px);
    color: #979797;
    cursor: pointer;
}

.duration-btn:hover {
    background-color: #b695f8;
    border: 1px solid #b695f8;
    color: #fff;
    transition: 0.5s ease;
}

/* .days-input-wrapper {
    
    border-radius: 10px;
    width: 100%;
    height: 6vh;
    position: relative;
    overflow: hidden;
}
.days-input {
    height: 100%;
    width: 100%;
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(10px, 12px, 14px);
    padding: 0 10px;
    
} */
.days-input-wrapper {
    position: relative;
}

.days-text {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'poppins';
    font-weight: 400;
    font-size: 10px;
    color: #666666;
}

.appointment-changes-msg {
    font-family: 'poppins';
    font-weight: 400;
    font-size: clamp(9px, 10px, 11px);
    color: #666;
}

.booking-btn {
    background-color: #b695f8;
    color: #fff;
    height: 35px;
    border-radius: 8px;
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    padding: 0 15px;
}

.service-cancel-btn {
    border: 1px solid #979797;
    border-radius: 8px;
    height: 35px;
    color: #979797;
    font-family: poppins;
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}

.service-save-btn {
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    color: #fff;
    border-radius: 8px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}

.add-new-question-btn {
    background: linear-gradient(180.04deg, #101828 1.28%, #0d1b37 99.81%, #0a1e46 99.96%);
    color: #fff;
    font-family: 'fira sans';
    font-weight: 500;
    font-size: clamp(10px, 12px, 14px);
    min-height: 5vh;
    border-radius: 8px;
    padding: 0 20px;
}

.questions-table {
    display: flex;
    font-family: 'poppins';
    font-weight: 500;
    font-size: clamp(12px, 13px, 15px);
}

.question-col {
    flex-grow: 1;
}

.question-col-large {
    flex-basis: 40%;
}

.booking-question-form {
    height: 80vh;
    position: relative;
}

.booking-pagination {
    position: absolute;
    bottom: 10px;
}
